import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';
// import { Member, MemberState } from 'src/@types/member';
import { TeamState, Team, TeamManager } from "src/@types/team";
import { setHeaders } from 'src/utils/jwt';

// ----------------------------------------------------------------------

const initialState: TeamState = {
  isLoading: false,
  error: null,
  teams: [],
  teamDetail: null,
  team: null,
};

const slice = createSlice({
  name: "teams",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Teams
    getTeamSuccess(state, action) {
      state.isLoading = false;
      state.teams = action.payload;
    },

    // CREATE TEAM
    createTeamSuccess(state, action) {
      state.isLoading = false;
      state.team = action.payload;
    },

    // GET TEAM DETAIL
    getTeamDetailSuccess(state, action) {
      state.teamDetail = action.payload;
    },

    // UPDATE TEAM
    updateTeamSuccess(state, action) {
      state.isLoading = false;
      state.team = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { } = slice.actions;

// ----------------------------------------------------------------------

export function getTeams(role?: string, filter?: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let config
      if (!(role === 'superadmin')) {
        config = setHeaders();
      }
      await axios.get(`/teams${filter ? filter : ``}`, config).then((res) => {
        const response: { team: { teams: Team[] } } = res.data;
        dispatch(slice.actions.getTeamSuccess(response.team));
        return res;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getTeamDetail(id: string) {
  return async () => {
    try {
      await axios.get(`/team/${id}`).then((res) => {
        const response: { data: { teamDetail: TeamManager } } = res.data;
        dispatch(slice.actions.getTeamDetailSuccess(response));
        return res;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createTeam(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios.post("/team/add", payload).then((res) => {
        const response: { data: { team: TeamManager } } = res;
        dispatch(slice.actions.createTeamSuccess(response.data));
        return res;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
      return error.error;
    }
  };
}

// ----------------------------------------------------------------------

export function updateTeam(role?: string, id?: string, payload?: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let config
      if (!(role === 'superadmin')) {
        config = setHeaders();
      }
      return await axios.patch(`/team/${id}`, payload, config).then((res) => {
        const response: { data: any } = res;
        dispatch(slice.actions.updateTeamSuccess(response.data));
        return res;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.error;
    }
  };
}

