// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, Container, Typography } from '@mui/material';
// routes
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Page from '../../components/Page';
import loginBackground from 'src/assets/images/loginBackground.jpeg';
import logo from 'src/assets/images/Logo.png';
// sections
import { LoginForm } from '../../sections/auth/login';
import { useTranslation } from 'react-i18next';
import LanguagePopover from 'src/layouts/dashboard/header/LanguagePopover';

// ----------------------------------------------------------------------

export const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

export const SectionStyle = styled('div')(({ theme }) => ({
  justifyContent: 'center',
  width: '100%',
  display: 'flex',
  backgroundSize: 'cover',
  backgroundImage: `url(${loginBackground})`,
}));

export const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export const AppLogoContainer = styled('div')(({ theme }) => ({
  alignSelf: 'center',
}));

export const AppLogo = styled('img')(({ theme }) => ({
  width: '8vw',
}));

export const BottomText = styled('p')(({ theme }) => ({
  alignSelf: 'center',
  height: '10%',
  position: 'absolute',
  bottom: '40px',
  color: '#00AB55',
  fontWeight: 'bold'
}));

export default function Login() {

  const mdUp = useResponsive('up', 'md');

  const { t } = useTranslation();

  return (
    <Page title={t('login')}>
    <Stack position={'absolute'} alignItems={'flex-end'} sx={{ mx: '95%' ,mt: '10px', WebkitAlignSelf: 'flex-start' }} >
      <LanguagePopover />
    </Stack>
      <RootStyle>
        {mdUp && (
          <SectionStyle>
            <AppLogoContainer>
              <AppLogo
                src={logo} 
                alt="login"
              />
            </AppLogoContainer>
            <BottomText sx={{ color: 'white' }}>{t('imageText')}</BottomText>
            <BottomText sx={{ pt: 4, color: 'white' }}>{t('imageText1')}</BottomText>
          </SectionStyle>
        )}
        <Container maxWidth="sm">
          <ContentStyle sx={{ p: 10 }}>
            <Stack direction="row" alignItems="center">
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" align='center'>
                  {t('loginHeading')}
                </Typography>
              </Box>
            </Stack>
            <LoginForm />
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page> 
  );
}