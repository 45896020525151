import * as Yup from "yup";
import { useState } from "react";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Stack, Alert, IconButton, InputAdornment } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// routes
// hooks
// components
import Iconify from "../../../components/Iconify";
import { FormProvider, RHFTextField } from "../../../components/hook-form";
import { PATH_AUTH, PATH_GROUP_PAGE } from "src/routes/paths";
import { useLocation, useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { useDispatch } from "src/redux/store";
import { resetPassword } from "src/redux/slices/auth";
import { useSnackbar } from "notistack";

// ----------------------------------------------------------------------

type FormValuesProps = {
  newPassword: string;
  password: string;
  afterSubmit?: string;
};

export default function ChangePasswordForm() {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required(t("newPasswordRequired"))
      .min(8, t("passwordValidate"))
      .max(72, t("passwordValidate1"))
      .matches(
        /^(?=.*[!@#$%^&*(),.?":{}|<>])(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
        t("passwordValidate2")
      ),
    password: Yup.string()
      .required(t("passwordRequired"))
      .oneOf([Yup.ref("newPassword"), null], t("passwordMustMatch")),
  });

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
    defaultValues: { password: "" },
  });

  const {
    reset,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      let payload = {
        email: location?.state?.locationData?.email,
        oldPassword: location?.state?.customData?.oldPassword,
        password: data?.password,
      };
      const response = await dispatch(resetPassword(payload));
      if (response && response.status === 200) {
        enqueueSnackbar("Password changed successfully", {
          variant: "success",
        });
        navigate(PATH_AUTH.login);
        return;
      } else {
        enqueueSnackbar("Invalid Temporary Password", { variant: "error" });
        reset();
        navigate(PATH_AUTH.emailVerification, {
          state: { email: location?.state?.locationData?.email },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <FormProvider
      methods={methods}
      onSubmit={handleSubmit(onSubmit)}
      authentication={true}
    >
      <Stack spacing={3} sx={{ mb: 2 }}>
        {!!errors.afterSubmit && (
          <Alert severity="error">{errors.afterSubmit.message}</Alert>
        )}
        <RHFTextField
          id={"changePassword.input.newPassword"}
          name="newPassword"
          label={t("enterNewPassword")}
          type={showNewPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowNewPassword(!showNewPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showNewPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <RHFTextField
          id={"changePassword.input.confirmPassword"}
          name="password"
          label={t("confirmPassword")}
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        id={"changePassword.button.submit"}
        variant="contained"
        loading={isSubmitting}
      >
        {t("submit")}
      </LoadingButton>
    </FormProvider>
  );
}
