import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Box, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

export const ModalContainer = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: "5px",
  padding: "30px",
  width: "500px",
}));

interface CustomModalProps {
  closeModal: VoidFunction;
  openModal: boolean;
  handleConfirm?: any;
  subHeading?: string;
  logoutConfirmationModal?: boolean;
}

export default function StatusModal({
  closeModal,
  openModal,
  handleConfirm,
  subHeading,
  logoutConfirmationModal,
}: CustomModalProps) {
  const { t } = useTranslation();
  return (
    <Modal
      open={openModal}
      onClose={closeModal}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <ModalContainer>
        <Box>
          <Typography variant="h5">{t("message1")}</Typography>
        </Box>
        <Box>
          <Typography variant="subtitle1">
            {subHeading || t("message2")}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "end", pt: 2 }}>
          <Button
            {...(logoutConfirmationModal ? { id: "logout.button.cancel" } : {})}
            variant="outlined"
            sx={{ mr: 1, width: "26%" }}
            onClick={closeModal}
          >
            {t("cancel")}
          </Button>
          <Button
            {...(logoutConfirmationModal
              ? { id: "logout.button.confirmLogout" }
              : {})}
            variant="contained"
            sx={{ width: logoutConfirmationModal ? "26%" : "full" }}
            onClick={handleConfirm}
          >
            {logoutConfirmationModal ? t("logout") : t("confirmDelete")}
          </Button>
        </Box>
      </ModalContainer>
    </Modal>
  );
}
