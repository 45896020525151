// @mui
import { Box, Typography, Stack } from "@mui/material";
// assets
import { UploadIllustration } from "../../assets";
import Iconify from "../Iconify";
import { useTranslation } from "react-i18next";

// ----------------------------------------------------------------------

export default function BlockContent(props: any) {
  const { title } = props;
  const { t } = useTranslation();
  return (
    <Stack
      spacing={2}
      display="flex"
      alignItems="center"
      justifyContent="start"
      direction={{ xs: "row" }}
      sx={{ width: 1, textAlign: { xs: "center", md: "left" } }}
    >
      {/* <UploadIllustration sx={{ width: 220 }} /> */}

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Iconify
          icon={"solar:gallery-add-bold"}
          width={24}
          height={24}
          sx={{ color: "GrayText" }}
        />
        <Typography
          variant="body2"
          sx={{ pl: 1, fontSize: "92%", color: "#95a2af" }}
        >
          {title}
        </Typography>
      </Box>
    </Stack>
  );
}
