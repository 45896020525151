// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const ar = {
  demo: {
    title: `Arabic`,
    introduction: `لوريم إيبسوم هو ببساطة نص شكلي يستخدم في صناعة الطباعة والتنضيد. كان Lorem Ipsum هو النص الوهمي القياسي في الصناعة منذ القرن الخامس عشر الميلادي ، عندما أخذت طابعة غير معروفة لوحًا من النوع وتدافعت عليه لعمل كتاب عينة. لقد نجت ليس فقط خمسة قرون ، ولكن أيضًا القفزة في التنضيد الإلكتروني ، وظلت دون تغيير جوهري. تم نشره في الستينيات من القرن الماضي بإصدار أوراق Letraset التي تحتوي على مقاطع Lorem Ipsum ، ومؤخرًا مع برامج النشر المكتبي مثل Aldus PageMaker بما في ذلك إصدارات Lorem Ipsum.`,
  },
  docs: {
    hi: `أهلا`,
    description: `تحتاج مساعدة؟ \n الرجاء مراجعة مستنداتنا`,
    documentation: `توثيق`,
  },
  app: `تطبيق`,
  user: `المستعمل`,
  list: `قائمة`,
  edit: "يحرر",
  shop: `متجر`,
  blog: `مقالات`,
  post: `بريد`,
  mail: `بريد`,
  chat: `دردشة`,
  cards: `البطاقات`,
  posts: `المشاركات`,
  create: `يزيد`,
  kanban: `كانبان`,
  general: `جنرال لواء`,
  banking: `الخدمات المصرفية`,
  booking: `الحجز`,
  profile: `الملف الشخصي`,
  account: `الحساب`,
  product: `المنتوج`,
  invoice: `فاتورة`,
  details: `تفاصيل`,
  checkout: `الدفع`,
  calendar: `التقويم`,
  analytics: `التحليلات`,
  ecommerce: `التجارة الإلكترونية`,
  management: `إدارة`,
  menu_level_1: `مستوى القائمة 1`,
  menu_level_2a: `مستوى القائمة 2 أ`,
  menu_level_2b: `مستوى القائمة 2 ب`,
  menu_level_3a: `مستوى القائمة 3 أ`,
  menu_level_3b: `مستوى القائمة 3 ب`,
  menu_level_4a: `مستوى القائمة 4 أ`,
  menu_level_4b: `مستوى القائمة 4 ب`,
  item_disabled: `العنصر معطل`,
  item_label: `تسمية العنصر`,
  item_caption: `عنوان العنصر`,
  item_external_link: `رابط خارجي للمادة`,
  description: `وصف`,
  other_cases: `حالات اخرى`,
  item_by_roles: `عنصر حسب الأدوار`,
  only_admin_can_see_this_item: `يمكن للمسؤول فقط رؤية هذا العنصر`,

  // Nav Config
  overview: "الخيارات",
  organization: `المجموعة`,
  members: "الأعضاء",
  teams: "الفرق",
  tournament: "البطولة / المعسكر",
  media: "الصور والفيديو",
  gps: "أداء اللاعبين",
  travel: "إجراءات السفر",
  health: "الحالة الصحية",
  kit: "الطقم الرياضي",
  inventoryStore: " المخزون",

  // Pages
  travelDocuments: "الرحلات: الوثائق",
  mediaImages: "وسائل الإعلام: الصور",
  gpsDocuments: "نظام تحديد المواقع: المستندات",
  healthDocuments: "الوثائق الصحية",

  // Input
  search: "البحث",
  organizationName: "اسم المجموعة",
  ownerName: "اسم المالك",
  ownerEmail: "البريد الإلكتروني للمالك",
  ownerPhoneNumber: "رقم هاتف المالك",
  country: "الدولة",
  state: "الولاية/المنطقة",
  city: "مدينة",
  zip: "الرمز البريدي",
  uploadProfile: "تحميل الملف الشخصي",
  firstName: "الاسم الأول",
  lastName: "اسم العائلة",
  localFirstName: "First Name",
  localLastName: "Last Name",
  emailAddress: "عنوان البريد الإلكتروني",
  addNewRole: "إضافة دور جديد",
  roleName: "اسم الدور",
  modulePermissions: "الأذونات",
  createNewRole: "+ إنشاء دور جديد",
  enterRole: "أدخل الوظيفة",
  enterTitle: "أدخل المسمى الوظيفي",
  enterPosition: "أدخل المركز",
  enterStatus: "أدخل الحالة ",
  dateofBirth: "تاريخ الميلاد",
  phoneNumber: "رقم الجوال",
  preferredLanguage: "اللغة المفضلة",
  password: "كلمة المرور",
  height: "الطول",
  weight: "الوزن",
  jerseySize: "مقاس الفانيلة",
  jerseyNumber: "رقم الفانيلة",
  internationalJerseyNumber: "رقم الفانيلة الدولي",
  shoeSize: "مقاس الحذاء",
  campTournamentCreator: "إضافة معسكر/بطولة",
  campTournamentApprover: "الموافقة على المعسكر/البطولة",
  campTournamentNotifier: "المخبر عن المعسكر/البطولة",
  bonusCreator: "إضافة المكافآت",
  bonusApprover: "الموافقة على المكافأة",
  disciplineCreator: "إضافة حالات الانضباط",
  disciplineApprover: "الموافقة على حالات الانضباط",
  mediaCreator: "إضافة صور وفيديو",
  gpsCreator: "إضافة أداء اللاعبين",
  healthCreator: "إضافة الحالات الصحية",
  kitCreator: "إضافة طقم اللاعبين",
  inventoryRequester: "طالب المخزون",
  enterTemporaryPassword: "أدخل كلمة المرور المؤقتة",
  enterOneTimePassword: "أدخل كلمة المرور لمرة واحدة",
  enterNewPassword: "أدخل كلمة المرور الجديدة",
  confirmPassword: "تأكيد كلمة المرور",
  enterOldPassword: "أدخل كلمة المرور القديمة",
  enterPassword: "أدخل كلمة المرور",
  enterTeamName: "أدخل اسم الفريق",
  enterTournamentName: "أدخل اسم البطولة / المعسكر",
  enterTeamStatus: "أدخل حالة الفريق",
  mediaUploadFor: "تحميل الصور والفيديو لـ:",
  gpsUploadFor: "تحميل GPS لـ:",
  players: "اللاعبين",
  notes: "الملحوظات",
  idType: "نوع الهوية",
  documentType: "اسم الملف",
  internationalClub: "النادي الدولي",
  itemDescription: "وصف السلعة",
  title: "العنوان",
  itemCounts: "عدد العناصر (1 أو أكثر)",
  postCommentToThisOrder: "أضف تعليقا على هذا الطلب",
  camp: "معسكر",
  tournaments: "البطولات",
  location: "موقع",
  approver: "الموافق",
  tournamentRequired: "اسم البطولة / المعسكر مطلوب",
  typeRequired: "النوع مطلوب",
  approverRequired: "اسم الموافق مطلوب",
  all: "الجميع",

  // permissions
  permissionRoster: "القوائم",
  permissionCampTournament: "المعسكرات والبطولات",
  permissionBonus: "المكافآت",
  permissionDiscipline: "المخالفات",
  permissionMedia: "الصور والفيديو",
  permissionGPS: "أداء اللاعبين",
  permissionHealth: "الحالة الصحية",
  permissionTravel: "إجراءات السفر",
  permissionKit: "الطقم الرياضي",
  permissionInventory: "المخزون",

  // Error text
  organizationText1: "اسم المجموعة مطلوب",
  limit: "الحد 80 حرفا",
  organizationText2: "يجب أن يحتوي اسم المجموعة على أحرف أبجدية فقط",
  organizationOwnerName: "اسم المالك مطلوب",
  emailRequired: "البريد الالكتروني مطلوب",
  validEmail: "أدخل عنوان بريد إلكتروني صالح",
  enterValidOtp: "أدخل كلمة مرور صالحه لمرة واحدة (OTP)",
  phoneRequired: "رقم الهاتف مطلوب",
  phoneNumValidate: "يجب أن يحتوي رقم الهاتف على قيم رقمية فقط",
  countryRequired: "الدولة مطلوبة",
  stateRequired: "الدولة مطلوبة",
  limit50: "الحد 50 حرفا",
  stateValidate: "يجب أن يحتوي اسم الولاية على أحرف أبجدية فقط",
  cityRequired: "اسم المدينة مطلوب",
  cityValidate: "يجب أن يحتوي اسم المدينة على أحرف أبجدية فقط",
  zipRequired: "الرمز البريدي مطلوب",
  zipValidate: "يجب أن يحتوي الرمز البريدي على قيم رقمية فقط",
  fNameRequired: "الإسم الأول مطلوب",
  firstNameValidate: "يجب أن يحتوي الاسم الأول على أحرف أبجدية فقط",
  lNameRequired: "إسم العائلة مطلوب",
  lNameValidate: "يجب أن يحتوي الاسم الأخير على أحرف أبجدية فقط",
  teamRequired: "اسم الفريق مطلوب",
  teamStatusRequired: "حالة الفريق مطلوبة",
  roleRequired: "الدور مطلوب",
  titleRequired: "العنوان مطلوب",
  positionRequired: "المنصب مطلوب",
  dobRequired: "تاريخ الميلاد مطلوب",
  phoneNumberRequired: "رقم الهاتف مطلوب",
  phoneNumberValidate: "يجب أن يحتوي رقم الهاتف على قيم رقمية فقط",
  passwordRequired: "كلمة المرور مطلوبة",
  confirmPasswordRequired: "تأكيد كلمة المرور مطلوب",
  passwordValidate: "يجب أن تكون كلمة المرور 8 أحرف على الأقل",
  passwordValidate1: "يجب أن تكون كلمة المرور أقل من 72 حرفًا",
  passwordValidate2:
    "يجب أن تحتوي كلمة المرور على حرف خاص واحد على الأقل، وحرف كبير واحد، وحرف صغير واحد، ورقم واحد.",
  passwordValidate3: "يجب أن تكون كلمة المرور أقل من 20 حرفًا",
  passwordValidate4:
    "يجب أن تكون كلمة المرور الجديدة وتأكيد كلمة المرور متماثلتين",
  healthRequired: "الحالة الصحية مطلوبة",
  heightRequired: "الطول مطلوب",
  heightValidate: "يجب أن يكون الطول قيمة رقمية",
  weightRequired: "الوزن مطلوب",
  weightValidate: "يجب أن يكون الوزن قيمة رقمية",
  jerseyRequired: "حجم الجيرسي مطلوب",
  jerseyNumberRequired: "رقم الجيرسي مطلوب",
  internationlJerseyRequired: "مطلوب رقم الجيرسي الدولي",
  shoeSizeRequired: "حجم الحذاء مطلوب",
  mediaTypeRequired: "نوع الوسائط مطلوب",
  gpsTypeRequired: "نوع GPS مطلوب",
  memberRequired: "مطلوب عضو",
  imagesRequired: "الصور مطلوبة",
  playerRequired: "مطلوب لاعب",
  attachmentsRequired: "المرفقات مطلوبة",
  idTypeRequired: "نوع الهوية مطلوب",
  DocumentTypeRequired: "نوع الوثيقة مطلوب",
  expiryDateRequired: "تاريخ انتهاء الصلاحية مطلوب",
  notesRequired: "الملاحظات مطلوبة",
  emailValidate: "يجب أن يكون البريد الإلكتروني عنوان بريد إلكتروني صالحًا",
  temporaryPasswordRequired: "كلمة المرور المؤقتة مطلوبة",
  oneTimePasswordRequired: "مطلوب كلمة مرور لمرة واحدة",
  oldPasswordRequired: "كلمة المرور القديمة مطلوبة",
  newPasswordValidate:
    "كلمة المرور الجديدة يجب أن تكون مختلفة عن كلمة المرور القديمة",
  newPasswordRequired: "كلمة المرور الجديدة مطلوبة",
  passwordMustMatch: "كلمة المرور يجب أن تتطابق مع كلمة المرور الجديدة",
  imageRequired: "الصورة مطلوبة",
  kitDateRequired: "تاريخ الطقم مطلوب",
  kitTimeRequired: "مطلوب وقت العدة",
  noKitStoreCreatedYet: "لم يتم إضافة متجر حتى الآن",
  quatityRequired: "الكمية مطلوبة",
  descriptionRequired: "الوصف مطلوب",
  noComments: "بدون تعليقات",

  // Pages
  organizationDetails: "تفاصيل المجموعة",
  generalInformation: "معلومات عامة",
  personalInformation: "معلومات شخصية",
  healthInformation: "معلومات صحية",
  apparelInformation: "معلومات الملابس",
  permissions: "الأذونات",
  orderInformation: "معلومات الطلب",
  itemInformation: "معلومات العنصر",

  // Member Details
  fullName: "الاسم الكامل",
  email: "البريد إلكتروني",
  memberTitle: "عنوان",
  onboard: "التسجيل",
  onboardingDate: "تاريخ التسجيل",
  itemName: "اسم العنصر",

  // Login Pages
  imageText: "جمع الفرق معًا، تطبيق واحد في كل مرة",
  imageText1: "SAFF مدعوم من",
  loginHeading: "قم بتسجيل الدخول إلى معسكر SNT",
  oneTimePassword: "كلمة السر لمرة واحدة",
  enterTheLoginCodeSent: "أدخل رمز الدخول المرسل إلى",
  forgotPassword: "هل نسيت كلمة السر؟",
  login: "تسجيل الدخول",
  sendOneTimePassword: "إرسال كلمة المرور لمرة واحدة",
  termAndConditions: "الأحكام والشروط",
  iAcceptTheseTermsAndConditions: "أوافق على هذه الشروط والأحكام",
  forgorPasswordText:
    "يرجى إدخال معرف البريد الإلكتروني الخاص بك أدناه، وسنشارك رابط إعادة تعيين كلمة المرور على بريدك الإلكتروني",
  submit: "يُقدِّم",
  emailVerificationText:
    "لقد أرسلنا عبر البريد الإلكتروني كلمة مرور مؤقتة. الرجاء إدخالها أدناه لإعداد كلمة مرور جديدة.",
  checkMail: "تحقق من بريدك الالكتروني",
  emailVerification: "تأكيد بواسطة البريد الالكتروني",
  proceed: "يتابع",
  resendMail: "إعادة إرسال البريد الإلكتروني",
  resendOneTimePassword: "أعد إرسال الرمز",
  changePassword: "تغيير كلمة المرور",
  changePasswordToNew: "تغيير كلمة المرور المؤقتة إلى كلمة المرور الجديدة",
  changeTempPassword: "تغيير كلمة المرور المؤقتة",

  // Table Headings
  name: "الاسم",
  team: "الفريق",
  role: "الوظيفة",
  position: "المركز",
  latestReport: "أحدث تقرير",
  healthStatus: "الحالة الصحية",
  teamName: "اسم الفريق",
  tournamentName: "اسم البطولة",
  teamStatus: "حالة الفريق",
  teamMembers: "أعضاء الفريق",
  createdOn: "تمت الإضافة في",
  type: "يكتب",
  startDate: "تاريخ البدء",
  status: "الحالة",
  imagesCount: "عدد الصور",
  noDocument: "رقم الوثيقة",
  date: "التاريخ",
  time: "وقت",
  staffPlayers: "طاقم الفريق/اللاعبين",
  options: "الخيارات",
  orderNoItems: "رقم الطلب",
  requiredDate: "التاريخ المطلوب",
  campTournament: "المعسكر/البطولة",
  requestedBy: "تم الطلب من قبل",
  orderStatus: "حالة الطلب",
  item: "عنصر",
  itemCount: "عدد العناصر",

  // Button
  upload: "إضافة",
  kitStore: "متجر طقم الفريق",
  store: "المتجر",
  requestItems: "طلب عناصر",
  createKit: "إضافة طقم فريق",
  addMember: "إضافة عضو",
  addTeams: "إضافة فريق",
  uploadMedia: "إضافة صور وفيديو",
  rename: "تغير",
  delete: "الحذف",
  folders: "الملف",
  addNewFolder: "إضافة ملف جديد",
  uploadImage: "إضافة صور",
  addFolder: "ملف جديد",
  renameFolder: "تغير الإسم",
  folderName: "إسم الملف",
  uploadGps: "إضافة أداء",
  addHealthStatus: "إضافة حالة صحية",
  uploadHealthStatus: "إضافة حالة صحية",
  viewId: "عرض الوثيقة",
  viewStatus: "عرض الحالة",
  recoveryDate: "تاريخ التعافي:",
  note: "ملحوظة",
  attachments: "المرفقات",
  addAttachment: "إضافة مرفق",
  addAttachments: "إضافة مرفقات)",
  saveChanges: "حفظ التغييرات",
  save: "حفظ",
  selectKitItemsFromStore: "حدد عناصر المجموعة من المتجر",
  addImages: "إضافة الصور",
  createAndSend: "إضافة وإرسال",
  next: "التالي",
  addItems: "إضافة عناصر",
  addItem: "إضافة عنصر",
  selectTeam: "اختر فريق",
  viewAll: "عرض الكل",
  add: "إضافة",
  addTournamentCamp: "إضافة البطولة / المعسكر",
  send: "يرسل",
  editTournamentCamp: "تعديل البطولة / المعسكر",

  // Image Input text
  imageInputText1: `مسموح به *.jpeg، *.jpg، *.png، *.gif`,
  imageInputText2: "الحد الأقصى لحجم",

  // Status Modal
  message1: "هل أنت متأكد؟",
  message2: "هل تريد حقاً حذف هذه الوثيقة؟",
  message3: "تسجيل الخروج؟",
  message4: "هل تريد حقاً تسجيل الخروج؟",
  message5: "هل تريد حقاً حذف هذا العنصر من المتجر؟",
  message6: "هل تريد حقًا حذف عنصر المجموعة هذا؟",
  cancel: "إلغاء",
  confirmDelete: "تأكيد الحذف",

  // Basic
  expiryDate: "تاريخ الانتهاء",
  addNewMember: "إضافة عضو جديد",
  addNewTeam: "إضافة فريق جديد",
  editTeam: "تعديل الفريق",
  teamInformation: "معلومات الفريق",
  addMedia: "إضافة صور وفيديو",
  addMediaFor: "إضافة وسائل الإعلام ل",
  addNewGps: "إضافة تقرير أداء",
  addGpsFor: "إضافة تقرير أداء ل",
  addNewTravel: "إضافة وثيقة سفر",
  addTravelFor: "أضف وثيقة سفر ل",
  travelInformation: "معلومات السفر",
  addNewTravelFor: "إضافة وثيقة سفر ل",
  addHealthStatusFor: "إضافة الحالة الصحية ل",
  statusDetails: "تفاصيل الحالة",
  addTeamMembers: "إضافة أعضاء الفريق",
  addTournamentMembers: "إضافة أعضاء البطولة",
  addMembers: "إضافة أعضاء",
  member: "العضو",
  allTeams: "جميع الفرق",
  allTypes: "كل الانواع",
  roles: "الأدوار",
  allRoles: "جميع الأدوار",
  allCampTournament: "جميع المعسكرات/البطولات",
  allOrderStatus: "كل حالة الطلب",
  editMember: "تعديل معلومات العضو",
  removeAll: "حذف الكل",
  editHealthStatus: "تعديل الحالة الصحية",
  noImagesFound: "لم يتم العثور على صور",
  selectDate: "اختر التاريخ",
  noData: "لايوجد بيانات",
  createdAt: "تمت الإضافة في",
  or: "-  أو  -",
  useEmail: "استخدم البريد الإلكتروني",
  usePhoneNumber: "استخدم رقم الهاتف",
  loginWithEmail: "تسجيل الدخول مع البريد الإلكتروني",
  loginWithPhoneNumber: "تسجيل الدخول بالهاتف",
  logout: "تسجيل خروج",
  createAndSendKit: "إنشاء وإرسال طقم الفريق",
  kitDate: "تاريخ طقم الفريق",
  startTime: "وقت طقم الفريق",
  selectImage: "اختر صورة",
  player: "اللاعب",
  staff: "طاقم عمل",
  orders: "طلبات",
  order: "طلب",
  itemDetails: "تفاصيل العنصر",
  addItemToStore: "إضافة عنصر إلى المتجر",
  editItem: "تعديل العنصر",
  count: "عدد",
  images: "الصور",
  updateStatus: "تحديث الحالة",
  updateItemStatus: "تحديث الحالة",
  forCampTournament: "للمعسكر/البطولة",
  comments: "تعليقات",
  loading: "...تحميل",
  permissionDenied: "تم رفض الإذن",
  youDoNotHavePermissionToAccessThisPage: "ليس لديك الصلاحية لدخول هذه الصفحة",

  // Dropdown
  activeFit: "نشط - صالح",
  activePartiallyFit: "نشط - نشط جزئياً",
  notActiveInjuredNeedsPhysio: "غير نشط - مصاب - يحتاج إلى طبيب",
  notActiveInjuredNeedsRest: "غير نشط - مصاب - يحتاج إلى الراحة",
  notActiveSick: "غير نشط - مريض",
  id: `الوثائق`,
  visas: "التأشيرات",
  ticketReservation: "التذاكر",
  active: "نشط",
  inactive: "غير نشط",
  english: "إنجليزي",
  arabic: "عربي",
  selectCampTournament: "حدد المعسكر / البطولة",
  selectOrderStatus: "حدد حالة الطلب",
};

export default ar;