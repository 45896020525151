import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';
import { InternationalClub, Member, MemberManager, MemberState, Roles } from 'src/@types/member';
import { setHeaders } from 'src/utils/jwt';

// ----------------------------------------------------------------------

const initialState: MemberState = {
  isLoading: false,
  error: null,
  members: [],
  memberDetail: null,
  roles: [],
  member: null,
  internationalClub: []
};

const slice = createSlice({
  name: 'members',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MEMBERS
    getMemberSuccess(state, action) {
      state.isLoading = false;
      state.members = action.payload;
    },

    // GET MEMBER DETAIL
    getMemberDetailSuccess(state, action) {
      state.memberDetail = action.payload
    },

    // CREATE MEMEBR 
    createMemberSuccess(state, action) {
      state.member = action.payload;
    },

    // UPDATE MEMBER
    updateMemberSuccess(state, action) {
      state.member = action.payload;
    },

    // NRP-T
    // CREATE ROLE
    createRoleSuccess(state, action) {
      state.roles.push(action.payload);
    },

    // GET ROLES
    getRolesSuccess(state, action) {
      state.roles = action.payload;
    },

    // GET INTERNATIONAL CLUB
    getInternationalClubSuccess(state, action) {
      state.internationalClub = action.payload;
    },

  },
});

// Reducer
export default slice.reducer;

// Actions
export const {

} = slice.actions;

// ----------------------------------------------------------------------

export function getMembers(role?: string, payload?: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let config
      if (!(role === 'superadmin')) {
        config = setHeaders();
      }
      await axios.get(`/users`, { ...payload, ...config }).then((res) => {
        const response: { data: { members: Member[] } } = res.data;
        dispatch(slice.actions.getMemberSuccess(response.data));
        return res;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getMemberDetail(id: string) {
  return async () => {
    try {
      await axios.get(`/user/${id}`).then((res) => {
        const response: { data: { memberDetail: Member } } = res.data;
        dispatch(slice.actions.getMemberDetailSuccess(response.data));
        return res;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createMember(payload: any) {
  return async () => {
    try {
      return await axios.post('/user/add', payload).then((res) => {
        const response: { data: { member: MemberManager } } = res;
        dispatch(slice.actions.createMemberSuccess(response.data));
        return res;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
      return error.error;
    }
  };
}

// ----------------------------------------------------------------------

export function UpdateMember(id: string, payload: any) {
  return async () => {
    try {
      return await axios.patch(`/user/${id}`, payload).then((res) => {
        const response: { data: any } = res;
        dispatch(slice.actions.updateMemberSuccess(response.data));
        return res;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.error;
    }
  };
}

// NRP-T
// ----------------------------------------------------------------------

export function createRole(payload: any) {
  return async () => {
    try {
      return await axios.post('/roles', payload).then((res) => {
        const response: { data: { roles: Roles } } = res.data;
        dispatch(slice.actions.createRoleSuccess(response.data));
        return res;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
      return error.error;
    }
  };
}

// ----------------------------------------------------------------------

export function getRoles() {
  return async () => {
    try {
      await axios.get(`/roles`).then((res) => {
        const response: { data: { roles: Roles[] } } = res.data;
        dispatch(slice.actions.getRolesSuccess(response.data));
        return res;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getInternationalClubs() {
  return async () => {
    try {
      await axios.get(`/international-clubs`).then((res) => {
        const response: { data: { internationalClub: InternationalClub[] } } = res.data;
        dispatch(slice.actions.getInternationalClubSuccess(response.data));
        return res;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------