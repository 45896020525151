import { memo } from 'react';
// @mui
import { Stack } from '@mui/material';
//
import { NavSectionProps } from '../type';
import NavList from './NavList';
import useAuth from 'src/hooks/useAuth';

// ----------------------------------------------------------------------

const hideScrollbar = {
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
} as const;

function NavSectionHorizontal({ navConfig }: NavSectionProps) {
  const { userRole } = useAuth();
  return (
    <Stack
      direction="row"
      justifyContent="center"
      sx={{ bgcolor: 'background.neutral', borderRadius: 1, px: 0.5 }}
    >
      <Stack direction="row" sx={{ ...hideScrollbar, py: 1 }}>
        {navConfig.map((group) => (
          <Stack key={group.subheader} direction="row" flexShrink={0}>
            {group.items.map((list) =>
              // NRP-T
              // list.title === 'organization' && !['admin', 'superadmin'].includes(userRole)
              // || list.title === 'members' && ['admin-health', 'admin-vitals', 'admin-media', 'kit-manager'].includes(userRole)
              // || list.title === 'teams' && ['admin', 'admin-health', 'admin-vitals', 'admin-media', 'manager',"technical-team" , 'kit-manager'].includes(userRole)
              // || list.title === 'media' && ['admin-health', 'admin-vitals', 'kit-manager'].includes(userRole)
              // || list.title === 'gps' && ['admin-health', 'admin-media', 'kit-manager'].includes(userRole)
              // || list.title === 'travel' && ['admin-health', 'admin-vitals', 'admin-media', 'kit-manager'].includes(userRole)
              // || list.title === 'health' && ['admin-vitals', 'admin-media', 'kit-manager'].includes(userRole)
              // || list.title === 'inventoryStore' && !['admin', 'superadmin', 'manager',"technical-team", 'kit-manager'].includes(userRole)
              //   ? null : (
                (
                  <NavList
                    key={list.title + list.path}
                    data={list}
                    depth={1}
                    hasChildren={!!list.children}
                  />
                )
            )}

          </Stack>
        ))}
      </Stack>
    </Stack>
  );
}


export default memo(NavSectionHorizontal);
