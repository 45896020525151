// form
import { useFormContext, Controller } from "react-hook-form";
// @mui
import { TextField, TextFieldProps } from "@mui/material";
import { MuiTelInput } from "mui-tel-input";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

// ----------------------------------------------------------------------

type IProps = {
  name: string;
  inputValue?: any;
  mobileInput?: boolean;
  loginInput?: boolean;
  handleChange?: any;
  phoneStyle?: any;
  phoneLabel?: string;
  id?: string;
};

type Props = IProps & TextFieldProps;

export default function RHFTextField({
  name,
  inputValue,
  mobileInput,
  loginInput,
  handleChange,
  phoneLabel,
  phoneStyle,
  id,
  ...other
}: Props) {
  const { control } = useFormContext();
  const { t } = useTranslation();

  let validPhoneNumber = false;
  const validatePhoneNumber = (
    inputNumber: string,
    country: any,
    isDirty: boolean,
    phoneLength: number
  ) => {
    if (isDirty) {
      if (
        inputNumber &&
        inputNumber?.replace(country.dialCode, "")?.trim() === ""
      ) {
        validPhoneNumber = false;
        return false;
      } else if (inputNumber.length < phoneLength) {
        validPhoneNumber = false;
        return false;
      }
      validPhoneNumber = true;
      return true;
    }
    validPhoneNumber = false;
    return false;
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) =>
        loginInput ? (
          <>
            <PhoneInput
              onChange={handleChange}
              placeholder={t("phoneNumber")}
              inputProps={{
                id: id,
                name: "phone",
                autoComplete: "off",
                required: true,
              }}
              country={"sa"}
              value={inputValue}
              isValid={(inputNumber, country: any, countries) => {
                const phoneLength = Math.ceil(
                  (
                    countries.filter(
                      (val: any) => val.dialCode === country.dialCode
                    )[0] as any
                  )?.format.length / 2
                );
                return validatePhoneNumber(
                  inputNumber,
                  country,
                  true,
                  phoneLength
                );
              }}
              specialLabel=""
            />
            {/* {(touched && error) && <p style={{color:'red'}} className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-filled MuiFormHelperText-marginDense">{error}</p> } */}
          </>
        ) : mobileInput ? (
          <MuiTelInput
            {...field}
            id={id}
            defaultCountry="US"
            fullWidth
            forceCallingCode={true}
            onChange={handleChange}
            value={inputValue}
            placeholder={t("phoneNumber")}
            label={phoneLabel}
            error={!!error}
            helperText={error?.message}
            sx={phoneStyle}
            autoComplete="off"
          />
        ) : (
          <TextField
            {...field}
            fullWidth
            id={id}
            value={
              typeof field.value === "number" && field.value === 0
                ? ""
                : field.value
            }
            error={!!error}
            helperText={error?.message}
            {...other}
          />
        )
      }
    />
  );
}
