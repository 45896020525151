import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage"
import { ref, deleteObject } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCMQ7GMle5JYSscr10C5shwpJuY9pzOKrw",
  authDomain: "snt-camp.firebaseapp.com",
  projectId: "snt-camp",
  storageBucket: "snt-camp.appspot.com",
  messagingSenderId: "531823951481",
  appId: "1:531823951481:web:835d9be46fe71c7b6c9ea8"
};

const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);

export async function deleteFile(file: { name: string }): Promise<void> {
  try {
    const storageRef: any = ref(storage, `files/${file.name}`);
    await deleteObject(storageRef);
    console.log('Deleted From FireBase Successfully');
    
  } catch (error) {
    console.error('Error deleting file:', error);
  }
}
