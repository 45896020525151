import jwtDecode from 'jwt-decode';
// routes
import { PATH_AUTH } from '../routes/paths';
//
import axios from './axios';
import { AxiosRequestConfig } from 'axios';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from 'src/services/firebase';

// ----------------------------------------------------------------------

const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode<{ exp: number }>(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const handleTokenExpired = (exp: number) => {
  let expiredTimer;

  const currentTime = Date.now();

  // Test token expires after 10s
  // const timeLeft = currentTime + 10000 - currentTime; // ~10s
  const timeLeft = exp * 1000 - currentTime;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {
    alert('Token expired');

    localStorage.removeItem('accessToken');

    window.location.href = PATH_AUTH.login;
  }, timeLeft);
};

const setSession = (accessToken: string | null) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // This function below will handle when token is expired
    const { exp } = jwtDecode<{ exp: number }>(accessToken); // ~3 days by minimals server
    // handleTokenExpired(exp);
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const decodeToken = (accessToken: string | null) => {
  if (accessToken) {
    const userObj = jwtDecode(accessToken);
    return userObj;
  }
}

const setHeaders = () => {
  const currentTeamId = localStorage.getItem('currentTeamId');
  const headers: AxiosRequestConfig['headers'] = {};
  if (currentTeamId) {
    headers['teamId'] = currentTeamId;
  }
  const config: AxiosRequestConfig = {
    headers,
  };
  return config;
}

// firebaseUtils.ts

export interface Attachment {
  id: string;
  url: string;
  type: string;
}

const uploadAttachments = async (attachments: any[], storagePath: string): Promise<Attachment[]> => {
  const arr: Attachment[] = [];

  const uploadTasks = attachments?.map(async (item: any) => {
    const storageRef = ref(storage, `${storagePath}/${item.name}`);
    await uploadBytes(storageRef, item);

    const downloadUrl = await getDownloadURL(storageRef);
    const obj: Attachment = {
      id: item.name,
      url: downloadUrl,
      type: item.type || 'image/jpeg', // You can customize the default type
    };
    arr.push(obj);
  });

  await Promise.all(uploadTasks);
  return arr;
};


export { isValidToken, setSession, decodeToken, setHeaders, uploadAttachments };
