// form
import { useFormContext, Controller } from "react-hook-form";
// @mui
import { FormHelperText } from "@mui/material";
// type
import {
  UploadAvatar,
  UploadMultiFile,
  UploadSingleFile,
  UploadProps,
  UploadMultiFileProps,
} from "../upload";
import { useTranslation } from "react-i18next";

// ----------------------------------------------------------------------

interface Props extends Omit<UploadProps, "file"> {
  id?: string;
  name: string;
  imageLabel?: string;
}

export function RHFUploadAvatar({ id, name, imageLabel, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const checkError = !!error && !field.value;

        return (
          <div>
            <UploadAvatar
              id={id}
              accept={{ "image/*": [] }}
              error={checkError}
              {...other}
              file={field.value}
              imageLabel={imageLabel}
            />
            {checkError && (
              <FormHelperText error sx={{ px: 2, textAlign: "center" }}>
                {error.message}
              </FormHelperText>
            )}
          </div>
        );
      }}
    />
  );
}

// ----------------------------------------------------------------------

export function RHFUploadSingleFile({ id, name, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const checkError = !!error && !field.value;

        return (
          <UploadSingleFile
            id={id}
            accept={{ "image/*": [] }}
            file={field.value}
            error={checkError}
            helperText={
              checkError && (
                <FormHelperText error sx={{ px: 2 }}>
                  {error.message}
                </FormHelperText>
              )
            }
            {...other}
          />
        );
      }}
    />
  );
}

// ----------------------------------------------------------------------

interface RHFUploadMultiFileProps extends Omit<UploadMultiFileProps, "files"> {
  id?: string;
  name: string;
  type?: string;
  title?: string;
}

export function RHFUploadMultiFile({
  id,
  name,
  type,
  title,
  ...other
}: RHFUploadMultiFileProps) {
  const { control } = useFormContext();
  const { t } = useTranslation();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const checkError = !!error && field.value?.length === 0;

        return (
          <UploadMultiFile
            id={id}
            accept={
              type && ["gps", "travel", "health"].includes(type)
                ? { "image/*": [], "application/pdf": [] }
                : { "image/*": [] }
            }
            files={field.value}
            error={checkError}
            title={title || t("addAttachments")}
            helperText={
              checkError && (
                <FormHelperText error sx={{ px: 2 }}>
                  {error?.message}
                </FormHelperText>
              )
            }
            {...other}
          />
        );
      }}
    />
  );
}
