import { m, AnimatePresence } from "framer-motion";
// @mui
import { alpha } from "@mui/material/styles";
import {
  List,
  IconButton,
  ListItemText,
  ListItem,
  Typography,
} from "@mui/material";
// utils
import { useTheme } from "@mui/material/styles";

import { fData } from "../../utils/formatNumber";
import getFileData from "../../utils/getFileData";
// type
import { UploadMultiFileProps } from "./type";
//
import Image from "../Image";
import Iconify from "../Iconify";
import { varFade } from "../animate";
import MultipleImage from "../MultipleImage";

// ----------------------------------------------------------------------

export default function MultiFilePreview({
  showPreview = false,
  files,
  onRemove,
}: UploadMultiFileProps) {
  const hasFile = files?.length > 0;
  const theme = useTheme();

  return (
    <List disablePadding sx={{ ...(hasFile && { my: 3 }) }}>
      <AnimatePresence>
        {files &&
          files?.map((file: any, index) => {
            const { key, name, size, preview, type } = getFileData(file, index);
            if (showPreview) {
              return (
                <ListItem
                  key={key}
                  component={m.div}
                  {...varFade().inRight}
                  sx={{
                    p: 0,
                    m: 0.5,
                    // width: '100%',
                    // height: 45,
                    width: 80,
                    height: 80,
                    borderRadius: 1.25,
                    overflow: "hidden",
                    position: "relative",
                    display: "inline-flex",
                    border: (theme) => `solid 1px ${theme.palette.divider}`,
                  }}
                >
                  {type === "application/pdf" ? (
                    <Iconify
                      icon={"fluent:document-pdf-20-regular"}
                      width={"100%"}
                      height={"100%"}
                    />
                  ) : (
                    <MultipleImage
                      alt="preview"
                      src={file?.url || preview}
                      ratio="1/1"
                    />
                  )}
                  {onRemove && (
                    <IconButton
                      size="small"
                      onClick={() => onRemove(file)}
                      sx={{
                        top: 6,
                        p: "2px",
                        right: 6,
                        position: "absolute",
                        // // color: 'common.white',
                        // backgroundColor: theme.palette.secondary.light
                        color: "common.white",
                        bgcolor: (theme) =>
                          alpha(theme.palette.grey[900], 0.72),
                        "&:hover": {
                          bgcolor: (theme) =>
                            alpha(theme.palette.grey[900], 0.48),
                        },
                      }}
                    >
                      <Iconify icon={"eva:close-fill"} />
                    </IconButton>
                  )}
                </ListItem>
              );
            }

            return (
              <ListItem
                key={key}
                component={m.div}
                {...varFade().inRight}
                sx={{
                  my: 1,
                  px: 2,
                  py: 0.75,
                  borderRadius: 0.75,
                  border: (theme) => `solid 1px ${theme.palette.divider}`,
                }}
              >
                <Iconify
                  icon={"eva:file-fill"}
                  sx={{ width: 28, height: 28, color: "text.secondary", mr: 2 }}
                />

                <ListItemText
                  primary={typeof file === "string" ? file : name}
                  secondary={typeof file === "string" ? "" : fData(size || 0)}
                  primaryTypographyProps={{ variant: "subtitle2" }}
                  secondaryTypographyProps={{ variant: "caption" }}
                />

                {onRemove && (
                  <IconButton
                    edge="end"
                    size="small"
                    onClick={() => onRemove(file)}
                  >
                    <Iconify icon={"eva:close-fill"} />
                  </IconButton>
                )}
              </ListItem>
            );
          })}
      </AnimatePresence>
    </List>
  );
}
