// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
  demo: {
    title: `English`,
    introduction: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
  },
  docs: {
    hi: `Hi`,
    description: `Need help? \n Please check our docs.`,
    documentation: `documentation`,
  },
  app: `app`,
  user: `user`,
  list: `list`,
  edit: `edit`,
  shop: `shop`,
  blog: `blog`,
  post: `post`,
  mail: `mail`,
  chat: `chat`,
  cards: `cards`,
  posts: `posts`,
  create: `create`,
  kanban: `kanban`,
  general: `general`,
  banking: `banking`,
  booking: `booking`,
  profile: `profile`,
  account: `account`,
  product: `product`,
  invoice: `invoice`,
  details: `details`,
  checkout: `checkout`,
  calendar: `calendar`,
  analytics: `analytics`,
  ecommerce: `e-commerce`,
  management: `management`,
  menu_level_1: `menu level 1`,
  menu_level_2a: `menu level 2a`,
  menu_level_2b: `menu level 2b`,
  menu_level_3a: `menu level 3a`,
  menu_level_3b: `menu level 3b`,
  menu_level_4a: `menu level 4a`,
  menu_level_4b: `menu level 4b`,
  item_disabled: `item disabled`,
  item_label: `item label`,
  item_caption: `item caption`,
  item_external_link: `item external link`,
  description: `description`,
  other_cases: `other cases`,
  item_by_roles: `item by roles`,
  only_admin_can_see_this_item: `Only admin can see this item`,

  // Nav Config
  overview: "Overview",
  organization: `Organization`,
  members: "Members",
  teams: "Teams",
  tournament: "Tournament / Camp",
  media: "Media",
  gps: "GPS",
  travel: "Travel",
  health: "Health",
  kit: "Kit",
  inventoryStore: "Inventory Store",

  // Pages
  travelDocuments: "Travel: Documents",
  mediaImages: "Media: Images",
  gpsDocuments: "GPS: Documents",
  healthDocuments: "Health Documents",

  // Input
  search: "Search",
  organizationName: "Organization Name",
  ownerName: "Owner Name",
  ownerEmail: "Owner Email",
  ownerPhoneNumber: "Owner Phone Number",
  country: "Country",
  state: "State/Region",
  city: "City",
  zip: "Zip/Code",
  uploadProfile: "Upload profile",
  firstName: "First name",
  lastName: "Last name",
  localFirstName: "الاسم الأول",
  localLastName: "اسم العائلة",
  emailAddress: "Email Address",
  addNewRole: "Add New Role",
  roleName: "Role Name",
  modulePermissions: "Module Permissions",
  createNewRole: "+ Create New Role",
  enterRole: "Enter role",
  enterTitle: "Enter title",
  enterPosition: "Enter Position",
  enterStatus: "Enter Member Status",
  dateofBirth: "Date of Birth",
  phoneNumber: "Phone Number",
  preferredLanguage: "Preferred Language",
  password: "Password",
  height: "Height",
  weight: "Weight",
  jerseySize: "Jersey Size",
  jerseyNumber: "Jersey Number",
  internationalJerseyNumber: "International Jersey Number",
  shoeSize: "Shoe Size",
  campTournamentCreator: "Camp/Tournament Creator",
  campTournamentApprover: "Camp/Tournament Approver",
  campTournamentNotifier: "Camp/Tournament Notifier",
  bonusCreator: "Bonus Creator",
  bonusApprover: "Bonus Approver",
  disciplineCreator: "Discipline Creator",
  disciplineApprover: "Discipline Approver",
  mediaCreator: "Media Creator",
  gpsCreator: "GPS Creator",
  healthCreator: "Health Creator",
  kitCreator: "Kit/Inventory Creator",
  inventoryRequester: "Inventory Requester",
  enterTemporaryPassword: "Enter Temporary Password",
  enterOneTimePassword: "Enter One-Time Password",
  enterNewPassword: "Enter New Password",
  confirmPassword: "Confirm Password",
  enterOldPassword: "Enter Old Password",
  enterPassword: "Enter Password",
  enterTeamName: "Enter Team Name",
  enterTournamentName: "Enter Tournament / Camp Name",
  enterTeamStatus: "Enter Team Status",
  mediaUploadFor: "Media Upload For :",
  gpsUploadFor: "GPS Upload For :",
  players: "Players",
  notes: "Notes",
  idType: "ID Type",
  documentType: "Document Name",
  internationalClub: "International Club",
  itemDescription: "Item Description",
  title: "Title",
  itemCounts: "Item Count (1 or more)",
  postCommentToThisOrder: "Post a comment to this order",
  camp: "Camp",
  tournaments: "tournament",
  location: "Location",
  approver: "Approver",
  all: "All",

  // permissions
  permissionRoster: "Roster",
  permissionCampTournament: "Camp/Tournament",
  permissionBonus: "Bonus",
  permissionDiscipline: "Discipline",
  permissionMedia: "Media",
  permissionGPS: "GPS",
  permissionHealth: "Health",
  permissionTravel: "Travel",
  permissionKit: "Kit",
  permissionInventory: "Inventory",

  // Error text
  organizationText1: "Organization Name is required",
  limit: "Limit of 80 characters",
  organizationText2:
    "Organization Name should only contain alphabetic characters",
  organizationOwnerName: "Owner Name is required",
  emailRequired: "Email is required",
  validEmail: "Enter a valid email address",
  enterValidOtp: "Enter a valid OTP",
  phoneRequired: "Phone Number is required",
  phoneNumValidate: "Phone Number must contain only numeric values",
  countryRequired: "Country is required",
  stateRequired: "State is required",
  limit50: "Limit of 50 characters",
  stateValidate: "State Name should only contain alphabetic characters",
  cityRequired: "City name is required",
  cityValidate: "City Name should only contain alphabetic characters",
  zipRequired: "ZipCode is required",
  zipValidate: "ZipCode must contain only numeric values",
  fNameRequired: "First name is required",
  firstNameValidate: "First name should only contain alphabetic characters",
  lNameRequired: "Last name is required",
  lNameValidate: "Last name should only contain alphabetic characters",
  teamRequired: "Team name is required",
  teamStatusRequired: "Team status is required",
  tournamentRequired: "Tournament / Camp name is required",
  typeRequired: "Type is required",
  approverRequired: "Approver name is required",
  roleRequired: "Role is required",
  titleRequired: "Title is required",
  positionRequired: "Position is required",
  dobRequired: "Date of birth is required",
  phoneNumberRequired: "Phone Number is required",
  phoneNumberValidate: "Phone Number must contain only numeric values",
  passwordRequired: "Password is required",
  confirmPasswordRequired: "Confirm password is required",
  passwordValidate: "Password must be at least 8 characters",
  passwordValidate1: "Password must be less than 72 characters",
  passwordValidate2:
    "Password must have at least one special character, one uppercase letter, one lowercase letter, and one number.",
  passwordValidate3: "Password must be less than 20 characters",
  passwordValidate4: "New password and confirm password must be same",
  healthRequired: "Health Status is required",
  heightRequired: "Height is required",
  heightValidate: "Height must be a numeric value",
  weightRequired: "Weight is required",
  weightValidate: "Weight must be a numeric value",
  jerseyRequired: "Jersey Size is required",
  jerseyNumberRequired: "Jersey Number is required",
  internationlJerseyRequired: "Internationl Jersey is required",
  shoeSizeRequired: "Shoe Size is required",
  mediaTypeRequired: "Media Type is required",
  gpsTypeRequired: "GPS Type is required",
  memberRequired: "Member is required",
  imagesRequired: "Images is required",
  playerRequired: "Player is required",
  attachmentsRequired: "Attachments is required",
  idTypeRequired: "ID Type is required",
  DocumentTypeRequired: "Document type is required",
  expiryDateRequired: "Expiry date is required",
  notesRequired: "Notes is required",
  emailValidate: "Email must be a valid email address",
  temporaryPasswordRequired: "Temporary password is required",
  oneTimePasswordRequired: "One-Time password is required",
  oldPasswordRequired: "Old password is required",
  newPasswordValidate: "New password must be different from the old password",
  newPasswordRequired: "New password is required",
  passwordMustMatch: "Password must match the new password",
  imageRequired: "Image is required",
  kitDateRequired: "Kit date is required",
  kitTimeRequired: "Kit time is required",
  noKitStoreCreatedYet: "No kit store created yet",
  quatityRequired: "Quantity is required",
  descriptionRequired: "Description is required",
  noComments: "No Comments !",

  // Pages
  organizationDetails: "Organization Details",
  generalInformation: "General Information:",
  personalInformation: "Personal Information:",
  healthInformation: "Health Information:",
  apparelInformation: "Apparel Information:",
  permissions: "Permissions:",
  orderInformation: "Order Information:",
  itemInformation: "Item Information:",

  // Member Details
  fullName: "Full Name",
  email: "Email",
  memberTitle: "Title",
  onboard: "Onboard",
  onboardingDate: "Onboarding Date",
  itemName: "Item Name",

  // Login Pages
  imageText: "Bringing Teams Together, One App at a Time!",
  imageText1: "Powered by SAFF",
  loginHeading: "Login to SNT Camp",
  forgotPassword: "Forgot password ?",
  login: "LOGIN",
  sendOneTimePassword: "Send One-Time Password",
  oneTimePassword: "One-Time Password",
  termAndConditions: "Terms And Conditions",
  iAcceptTheseTermsAndConditions: "I accept these terms and conditions",
  enterTheLoginCodeSent: "Enter the login code sent to",
  forgorPasswordText:
    "Please enter your email ID below, we will share the password reset link on your email",
  submit: "SUBMIT",
  emailVerificationText:
    "We have emailed a temporary password. Please enter it below to set up a new password.",
  checkMail: "Check your email",
  emailVerification: "Email Verification",
  proceed: "PROCEED",
  resendMail: "RESEND EMAIL",
  resendOneTimePassword: "RESEND CODE",
  changePassword: "Change Password",
  changePasswordToNew: "Change Temporary Password To New Password",
  changeTempPassword: "Change Temporary Password",

  // Table Headings
  name: "Name",
  team: "Team",
  role: "Role",
  position: "Position",
  latestReport: "Latest Report",
  healthStatus: "Health Status",
  teamName: "Team name",
  tournamentName: "Tournament name",
  teamStatus: "Team status",
  teamMembers: "Team Members",
  createdOn: "Created on",
  type: "Type",
  startDate: "Start Date",
  status: "Status",
  imagesCount: "Images Count",
  noDocument: "No. of Document",
  date: "Date",
  staffPlayers: "Staff/Players",
  options: "Options",
  orderNoItems: "Order # (No. of items)",
  requiredDate: "Required Date",
  campTournament: "Camp/Tournament",
  requestedBy: "Requested by",
  orderStatus: "Order Status",
  item: "Item",
  itemCount: "Item Count",

  // Button
  upload: "Upload",
  kitStore: "Kit Store",
  store: "Store",
  requestItems: "Request Items",
  createKit: "Create Kit",
  addMember: "Add member",
  addTeams: "Add teams",
  uploadMedia: "Upload Media",
  rename: "Rename",
  delete: "Delete",
  folders: "Folders",
  addNewFolder: "Add New Folder",
  uploadImage: "Upload Image",
  addFolder: "New Folder",
  renameFolder: "Rename Folder",
  folderName: "Folder Name",
  uploadGps: "Upload GPS",
  addHealthStatus: "Add Health Status",
  uploadHealthStatus: "Upload Health Status",
  viewId: "VIEW ID",
  viewStatus: "VIEW STATUS",
  recoveryDate: "Recovery Date:",
  note: "Note",
  attachments: "Attachments:",
  addAttachment: "Add Attachment",
  addAttachments: "Add Attachment(s)",
  saveChanges: "Save Changes",
  save: "Save",
  selectKitItemsFromStore: "Select kit items from store",
  addImages: "Add Images",
  createAndSend: "Create and Send",
  next: "Next",
  addItems: "Add Items",
  addItem: "Add Item",
  selectTeam: "Select Team :",
  viewAll: "View All",
  add: "Add",
  addTournamentCamp: "Add Tournament / Camp",
  send: "Send",
  editTournamentCamp: "Edit Tournament / Camp",

  // Image Input text
  imageInputText1: `Allowed *.jpeg, *.jpg, *.png, *.gif`,
  imageInputText2: "max size of",

  // Status Modal
  message1: "Are you sure ?",
  message2: "Do you really want to delete this document?",
  message3: "Close this app ?",
  message4: "Do you really want to logout",
  message5: "Do you really want to delete this store item?",
  message6: "Do you really want to delete this kit item?",
  cancel: "Cancel",
  confirmDelete: "Confirm Delete",

  // Basic
  expiryDate: "Expiry Date",
  addNewMember: "Add New Member",
  addNewTeam: "Add New Team",
  editTeam: "Edit Team",
  teamInformation: "Team Information:",
  addMedia: "Add Media",
  addMediaFor: "Add Media For",
  addNewGps: "Add New GPS",
  addGpsFor: "Add GPS For",
  addNewTravel: "Add New Travel",
  addTravelFor: "Add Travel For",
  travelInformation: "Travel Information:",
  addNewTravelFor: "Add New Travel For",
  addHealthStatusFor: "Add Health Status For",
  statusDetails: "Status Details",
  addTeamMembers: "Add Team Members",
  addTournamentMembers: "Add Tournament Members",
  addMembers: "Add Members",
  member: "Member",
  allTeams: "All Teams",
  allTypes: "All Types",
  roles: "Roles",
  allRoles: "All Roles",
  allCampTournament: "All Camp / Tournament",
  allOrderStatus: "All Order Status",
  editMember: "Edit Member",
  removeAll: "Remove all",
  editHealthStatus: "Edit Health Status",
  noImagesFound: "No Images Found !",
  selectDate: "Search by Date",
  noData: "No Data !",
  createdAt: "Created At",
  or: "-  or  -",
  loginWithEmail: "Login with email",
  loginWithPhoneNumber: "Login with phone",
  useEmail: "USE EMAIL",
  usePhoneNumber: "USE PHONE NUMBER",
  logout: "Logout",
  createAndSendKit: "Create and Send Kit",
  kitDate: "Kit Date",
  startTime: "Kit Time",
  selectImage: "Select Image",
  player: "Player",
  staff: "Staff",
  orders: "Orders",
  order: "Order",
  itemDetails: "Item Details",
  addItemToStore: "Add Item to Store",
  editItem: "Edit Store Item",
  count: "Count",
  images: "Images",
  updateStatus: "UPDATE STATUS",
  updateItemStatus: "Update Status",
  forCampTournament: "For Camp/Tournament",
  comments: "Comments",
  loading: "Loading...",
  permissionDenied: "Permission Denied",
  youDoNotHavePermissionToAccessThisPage:
    "You do not have permission to access this page",

  // Dropdown
  activeFit: "Active - Fit",
  activePartiallyFit: "Active - Partially Fit",
  notActiveInjuredNeedsPhysio: "Not Active - Injured - Needs Physio",
  notActiveInjuredNeedsRest: "Not Active - Injured - Needs Rest",
  notActiveSick: "Not Active - Sick",
  id: `ID's`,
  visas: "Visas",
  ticketReservation: "Ticket & Reservation",
  active: "Active",
  inactive: "Inactive",
  english: "English",
  arabic: "Arabic",
  selectCampTournament: "Select Camp / Tournament",
  selectOrderStatus: "Select Order Status",
};

export default en;
