import { createContext, ReactNode, useEffect, useReducer } from "react";
// utils
import axios from "../utils/axios";
import { isValidToken, setSession, decodeToken } from "../utils/jwt";
// @types
import { ActionMap, AuthState, JWTContextType } from "../@types/auth";

// ----------------------------------------------------------------------

enum Types {
  Initial = "INITIALIZE",
  Login = "LOGIN",
  Logout = "LOGOUT",
  Register = "REGISTER",
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: any;
    userRole?: string;
    permission?: any;
  };
  [Types.Login]: {
    user: any;
    isAuthenticated: boolean;
    userRole?: string;
    permission?: any;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    user: any;
    userRole?: string;
    permission?: any;
  };
};

export type JWTActions =
  ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: {},
  userRole: "", // Set the default role here
  permission: [],
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case "INITIALIZE":
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
        userRole: action.payload.userRole || "",
        permission: action.payload.permission || "",
      };
    case "LOGIN":
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        userRole: action.payload.userRole || "",
        permission: action.payload.permission || "",
      };
    case "LOGOUT":
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        userRole: "",
        permission: "",
      };
    case "REGISTER":
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        userRole: action.payload.userRole || "",
        permission: action.payload.permission || "",
      };
    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = localStorage.getItem("accessToken");

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await axios.get("/user/me");
          const user = response.data.data;
          const userRole = response?.data?.data?.role?.slug;
          const permission = response?.data?.data?.permission;
          
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: true,
              user,
              userRole,
              permission,
            },
          });
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null,
              userRole: "",
              permission: [],
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null,
            userRole: "",
            permission: [],
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (payload: any) => {
    return axios
      .post("otp/login", payload)
      .then(async (res) => {        
        if (res && res.data.data.token && res?.data?.data?.role !== "player") {
          if (res.data.data.onboard) {
            setSession(res.data.data.token);
            localStorage.setItem(
              "currentTeamId",
              res?.data?.data?.currentTeamId
            );
            const TokenData = decodeToken(res.data.data.token); 
            const userRole = res?.data?.data?.role;
            const permission = res?.data?.data?.permission;
            const response = await axios.get("/user/me").catch((err) => 
              {console.error(err)
            });

            const userRes = response?.data?.data;

            dispatch({
              type: Types.Login,
              payload: {
                isAuthenticated: true,
                user: userRes,
                userRole,
                permission,
              },
            });
          } else {
            return res;
          }
        }
        return res;
      })
      .catch((err) => {
        return err.error;
      });
  };

  const onboard = async (token: string | null, data: any) => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    return axios
      .put("onboard", {}, config)
      .then((res) => {
        if (res && res?.data && res?.data?.statusCode === 200) {
          setSession(data.data.data.token);
          localStorage.setItem(
            "currentTeamId",
            data?.data?.data?.currentTeamId
          );
          const TokenData = decodeToken(data.data.data.token);
          const userRole = data?.data?.data?.role;
          const permission = data?.data?.data?.permission;
          dispatch({
            type: Types.Login,
            payload: {
              isAuthenticated: true,
              user: TokenData,
              userRole,
              permission,
            },
          });
        }
        return data;
      })
      .catch((err) => {
        return err.error;
      });
  };

  const register = async (
    email: string,
    password: string,
    firstName: string,
    lastName: string
  ) => {
    const response = await axios.post("/api/account/register", {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    localStorage.setItem("accessToken", accessToken);

    dispatch({
      type: Types.Register,
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: Types.Logout });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        login,
        onboard,
        logout,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
