import { m } from "framer-motion";
// @mui
import { Container, Typography } from "@mui/material";
// hooks
import useAuth from "../hooks/useAuth";
// components
import { MotionContainer, varBounce } from "../components/animate";
// assets
import { ForbiddenIllustration } from "../assets";
import { useTranslation } from "react-i18next";

// ----------------------------------------------------------------------

type RoleBasedGuardProp = {
  hasContent?: boolean;
  roles?: string[];
  // NRP-T
  permissions?: string[];
  children: React.ReactNode;
};

export default function RoleBasedGuard({
  hasContent,
  roles,
  // NRP-T
  permissions,
  children,
}: RoleBasedGuardProp) {
  // Logic here to get current user role
  // NRP-T
  // const { userRole } = useAuth();
  const { userRole, permission } = useAuth();
  const { t } = useTranslation();

  // NRP-T
  // if (typeof roles !== "undefined" && !roles.includes(userRole)) {
  if (
    userRole !== "superadmin" &&
    !permissions?.some((p) => permission.includes(p))
  ) {
    return hasContent ? (
      <Container
        component={MotionContainer}
        sx={{ textAlign: "center", mt: 12 }}
      >
        <m.div variants={varBounce().in}>
          <Typography variant="h3" paragraph>
            {t("permissionDenied")}
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: "text.secondary" }}>
            {t("youDoNotHavePermissionToAccessThisPage")}
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <ForbiddenIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
        </m.div>
      </Container>
    ) : null;
  }

  return <>{children}</>;
}
