import { useSnackbar } from 'notistack';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar } from '@mui/material';
// hooks
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
import useAuth from '../../../hooks/useAuth';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { HEADER, NAVBAR } from '../../../config';
// routes
import { PATH_AUTH } from '../../../routes/paths';
// components
import Logo from '../../../components/Logo';
import Iconify from '../../../components/Iconify';
import { IconButtonAnimate } from '../../../components/animate';

import LanguagePopover from './LanguagePopover';

import { useNavigate } from 'react-router';
import { useEffect } from 'react';
import { useDispatch } from 'src/redux/store';
import { getMembers, getRoles } from 'src/redux/slices/memberSlice';
import { getHealth } from 'src/redux/slices/healthSlice';
import { getTravels } from 'src/redux/slices/travelSlice';
import { getVitals } from 'src/redux/slices/vitalsSlice';
import { getMedia } from 'src/redux/slices/mediaSlice';
import { getTeams } from 'src/redux/slices/teamSlice';
import { getTournaments } from 'src/redux/slices/tournamentSlice';

type RootStyleProps = {
  isCollapse: boolean;
  isOffset: boolean;
  verticalLayout: boolean;
};

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) =>
    prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})<RootStyleProps>(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: 'none',
  // height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    ...(isCollapse && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
    ...(verticalLayout && {
      width: '100%',
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      backgroundColor: theme.palette.background.default,
    }),
  },
}));

// ----------------------------------------------------------------------

type Props = {
  onOpenSidebar: VoidFunction;
  isCollapse?: boolean;
  verticalLayout?: boolean;
};

export default function DashboardHeader({
  onOpenSidebar,
  isCollapse = false,
  verticalLayout = false,
}: Props) {
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;

  const isDesktop = useResponsive('up', 'lg');

  const { user, logout, userRole } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    Promise.all([
      dispatch(getRoles()),
      dispatch(getMembers(userRole)),
      dispatch(getTeams(userRole)),
      dispatch(getTournaments(userRole)),
      userRole && ['admin', 'superadmin', 'admin-media', 'manager', "technical-team" ].includes(userRole) &&  dispatch(getMedia(userRole)),
      userRole && ['admin', 'superadmin', 'admin-vitals', 'manager', "technical-team" ].includes(userRole) && dispatch(getVitals(userRole)),
      userRole && ['admin', 'superadmin', 'manager', "technical-team" ].includes(userRole) && dispatch(getTravels(userRole)),
      userRole && ['admin', 'superadmin', 'admin-health', 'manager', "technical-team" ].includes(userRole) && dispatch(getHealth(userRole)),
    ])
  }, [userRole])


  return (
    <RootStyle isCollapse={isCollapse} isOffset={isOffset} verticalLayout={verticalLayout}>
      <Toolbar
        sx={{
          minHeight: '100% !important',
          px: { lg: 5 },
        }}
      >
        {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}

        {!isDesktop && (
          <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>
        )}

        <Box sx={{ flexGrow: 1 }} />
      </Toolbar>
    </RootStyle>
  );
}
