import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";
import { AuthStates } from "src/@types/auth";

// ----------------------------------------------------------------------

const initialState: AuthStates = {
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {} = slice.actions;

// ----------------------------------------------------------------------

export function changePassword(payload: any, token: string | null) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await axios.put(
        `/user/change-password`,
        payload,
        config
      );
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

// ----------------------------------------------------------------------

export function forgetPassword(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios.post(`users/forgot-password`, payload).then((res) => {
        return res;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.error;
    }
  };
}

// ----------------------------------------------------------------------

export function resetPassword(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios.post(`users/reset-password`, payload).then((res) => {
        return res;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.error;
    }
  };
}

// ----------------------------------------------------------------------

export function sendOtp(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios.post(`/sendOtp`, payload).then((res) => {
        return res;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.error;
    }
  };
}