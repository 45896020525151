import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";
import { TravelManager, Travels, TravelState } from "src/@types/travel";
import { AxiosRequestConfig } from "axios";
import { setHeaders } from "src/utils/jwt";

// ----------------------------------------------------------------------

const initialState: TravelState = {
  isLoading: false,
  error: null,
  travels: [],
  travelDetails: null,
  travel: [],
  travelUserDetail: [],
};

const slice = createSlice({
  name: "travels",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET TRAVELS
    getTravelSuccess(state, action) {
      state.isLoading = false;
      state.travel = action.payload;
    },
    // GET HEALTH DETAIL
    getTravelDetail(state, action) {
      state.isLoading = false;
      state.travelUserDetail = action.payload;
    },
    // UPLOAD Travels DOCS
    uploadTravelDocs(state, action) {
      state.travelDetails = action.payload;
    },
    // Delete Travel DOCS
    deleteTravelDocs(state, action) {
      state.isLoading = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {} = slice.actions;

// ----------------------------------------------------------------------

export function getTravels(role?: string, payload?: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let config;
      if (!(role === "superadmin")) {
        config = setHeaders();
      }
      await axios.get(`/travels`, { ...payload, ...config }).then((res) => {
        const response: { data: { travel: Travels[] } } = res.data;
        dispatch(slice.actions.getTravelSuccess(response.data));
        return res;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function uploadTravelDocs(role: string, payload: any) {
  return async () => {
    try {
      let config;
      if (!(role === "superadmin")) {
        config = setHeaders();
      }
      return await axios.post(`/travels`, payload, config).then((res) => {
        const response: { data: { travelDetails: TravelManager } } = res;
        dispatch(slice.actions.uploadTravelDocs(response.data));
        return res;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
      return error.error;
    }
  };
}

export function getTravelsDetails(role?: string, id?: string | undefined) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let config;
      if (!(role === "superadmin")) {
        config = setHeaders();
      }
      return await axios.get(`/travel-user/${id}`, config).then((res) => {
        const response: { data: { travelUserDetail: Travels } } = res.data;
        dispatch(slice.actions.getTravelDetail(response.data));
        return res;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function deleteTravelDocs(id: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios.delete(`/travels/${id}`).then((res) => {
        const response: { data: any } = res;
        dispatch(slice.actions.deleteTravelDocs(response.data));
        return res;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
