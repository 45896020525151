import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';
import { TournamentManager, Tournaments, TournamentState } from 'src/@types/tournament';
import { setHeaders } from 'src/utils/jwt';

// ----------------------------------------------------------------------

const initialState: TournamentState = {
  isLoading: false,
  error: null,
  tournament: [],
  tournamentDetail: null,
  tournaments: null
};

const slice = createSlice({
  name: "tournaments",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET TOURNAMENTS
    getTorunamentSuccess(state, action) {
      state.isLoading = false;
      state.tournament = action.payload;
    },

    // GET TOURNAMENT DETAIL
    getTournamentDetailSuccess(state, action) {
      state.tournamentDetail = action.payload;
    },

     // CREATE TOURNAMENT
     createTournamnetSuccess(state, action) {
      state.isLoading = false;
      state.tournaments = action.payload;
    },

    // UPDATE TOURNAMENT
    updateTournamentSuccess(state,action){
      state.isLoading = false;
      state.tournaments = action.payload;
    }
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {

} = slice.actions;

// ----------------------------------------------------------------------

export function getTournaments(role?: string, filter?: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let config
      if (!(role === 'superadmin')) {
        config = setHeaders();
      }
      await axios.get(`/camp-tournaments${filter ? filter : ``}`, config).then((res) => {
        const response: { data: { tournament: Tournaments[] } } = res.data;
        dispatch(slice.actions.getTorunamentSuccess(response.data));
        return res;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getTournamentDetails(role?: string, id?: string) {
  return async () => {
    try {
      let config
      if (!(role === 'superadmin')) {
        config = setHeaders();
      }
      await axios.get(`/camp-tournament/${id}`, config).then((res) => {
        const response: { data: { tournamentDetail: TournamentManager } } = res.data;
        dispatch(slice.actions.getTournamentDetailSuccess(response.data));
        return res;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createTournament(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios.post("/camp-tournament/add", payload).then((res) => {
        const response: { data: { tournaments: TournamentManager } } = res;
        dispatch(slice.actions.createTournamnetSuccess(response.data));
        return res;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
      return error.error;
    }
  };
}

// ----------------------------------------------------------------------

export function updateTournament(role?: string, id?: string, payload?: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let config
      if (!(role === 'superadmin')) {
        config = setHeaders();
      }
      return await axios.patch(`/camp-tournament/${id}`, payload, config).then((res) => {
        const response: { data: any } = res;
        dispatch(slice.actions.updateTournamentSuccess(response.data));
        return res;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.error;
    }
  };
}