// routes
import { PATH_GROUP_PAGE } from "../../../routes/paths";
// components
import SvgIconStyle from "../../../components/SvgIconStyle";
import Iconify from "src/components/Iconify";
import styled from "@emotion/styled";
// NRP-T
import { PERMISSION_TYPE } from "src/@types/permissionConstants";

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  banking: getIcon("ic_banking"),
};

const IconStyle = styled(Iconify)(({ theme }) => ({
  width: 10,
  height: 10,
  marginTop: 1,
  flexShrink: 0,
}));

const navConfig = [
  // Group Admin Nav
  // ----------------------------------------------------------------------
  {
    subheader: "overview",
    items: [
      // { title: 'organization',path: PATH_GROUP_PAGE.organization.list, icon: ICONS.banking },
      {
        id: "navbar.nav.members",
        title: "members",
        path: PATH_GROUP_PAGE.members.list,
        icon: <IconStyle icon={"solar:users-group-rounded-bold"} />,
        // NRP-T
        // roles: ["admin", "superadmin", "manager", "technical-team"],
      },
      {
        id: "navbar.nav.teams",
        title: "teams",
        path: PATH_GROUP_PAGE.teams.list,
        icon: <IconStyle icon={"fa6-solid:users"} />,
        // NRP-T
        // roles: ["superadmin"],
      },
      {
        id: "navbar.nav.tournament",
        title: "tournament",
        path: PATH_GROUP_PAGE.tournament.list,
        icon: <IconStyle icon={"ion:calendar"} />,
        // NRP-T
        // roles: ["admin", "superadmin", "manager", "technical-team", "admin-media", "admin-vitals", "admin-health", "kit-manager"],
        permissions: [PERMISSION_TYPE.campTournament],
      },
      {
        id: "navbar.nav.media",
        title: "media",
        path: PATH_GROUP_PAGE.media.list,
        icon: <IconStyle icon={"solar:gallery-bold"} />,
        // NRP-T
        // roles: ["admin", "superadmin", "manager", "technical-team", "admin-media"],
        permissions: [PERMISSION_TYPE.media],
      },
      {
        id: "navbar.nav.gps",
        title: "gps",
        path: PATH_GROUP_PAGE.vitals.list,
        icon: <IconStyle icon={"mdi:clipboard-vitals"} />,
        // NRP-T
        // roles: ["admin", "superadmin", "manager", "technical-team", "admin-vitals"],
        permissions: [PERMISSION_TYPE.gps],
      },
      {
        id: "navbar.nav.travel",
        title: "travel",
        path: PATH_GROUP_PAGE.travel.list,
        icon: <IconStyle icon={"ic:baseline-flight"} />,
        // NRP-T
        // roles: ["admin", "superadmin", "manager", "technical-team"],
        permissions: [PERMISSION_TYPE.travel],
      },
      {
        id: "navbar.nav.health",
        title: "health",
        path: PATH_GROUP_PAGE.health.list,
        icon: <IconStyle icon={"ph:heart-fill"} />,
        // NRP-T
        // roles: ["admin", "superadmin", "manager", "technical-team", "admin-health"],
        permissions: [PERMISSION_TYPE.health],
      },
      {
        id: "navbar.nav.kit",
        title: "kit",
        path: PATH_GROUP_PAGE.kit.list,
        icon: <IconStyle icon={"ph:t-shirt-fill"} />,
        // NRP-T
        // roles: ["admin", "superadmin", "manager", "technical-team", "kit-manager"],
        permissions: [PERMISSION_TYPE.kit],
      },
      {
        id: "navbar.nav.inventoryStore",
        title: "inventoryStore",
        path: PATH_GROUP_PAGE.inventoryStore.list,
        icon: <IconStyle icon={"majesticons:shopping-cart-line"} />,
        // NRP-T
        // roles: ["admin", "superadmin", "manager", "technical-team", "kit-manager"],
        permissions: [PERMISSION_TYPE.inventory],
      },
    ],
  },
];

export default navConfig;
