import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";
import { HealthManager, Healths, HealthState } from "src/@types/health";
import { AxiosRequestConfig } from "axios";
import { setHeaders } from "src/utils/jwt";
// ----------------------------------------------------------------------
const initialState: HealthState = {
  isLoading: false,
  error: null,
  health: [],
  healthManager: null,
  healthDetail: [],
};
const slice = createSlice({
  name: "health",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // GET HEALTH
    getHealthSuccess(state, action) {
      state.isLoading = false;
      state.health = action.payload;
    },
    // GET HEALTH DETAIL
    getHealthDetail(state, action) {
      state.isLoading = false;
      state.healthDetail = action.payload;
    },
    // UPLOAD HEALTH DOCS
    uploadHealthDocuments(state, action) {
      state.healthManager = action.payload;
    },
    // Delete HEALTH DOCS
    deleteHealthDocs(state, action) {
      state.isLoading = false;
    },

    // UPDATE Health
    updateHealthSuccess(state, action) {
      state.isLoading = false;
      state.healthManager = action.payload;
    },
  },
});
// Reducer
export default slice.reducer;
// Actions
export const {} = slice.actions;

// ----------------------------------------------------------------------

export function getHealth(role?: string, payload?: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let config;
      if (!(role === "superadmin")) {
        config = setHeaders();
      }
      await axios
        .get(`/health-vitales?type=health`, { ...payload, ...config })
        .then((res) => {
          const response: { data: { health: Healths[] } } = res.data;
          dispatch(slice.actions.getHealthSuccess(response.data));
          return res;
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getHealthDetails(role: string, payload?: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let config;
      if (!(role === "superadmin")) {
        config = setHeaders();
      }
      return await axios
        .get(`/health-vitales-user`, { ...payload, ...config })
        .then((res) => {
          const response: { data: { healthDetail: Healths } } = res.data;
          dispatch(slice.actions.getHealthDetail(response.data));
          return res;
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------
export function uploadHealthDocs(role: string, payload: any) {
  return async () => {
    try {
      let config;
      if (!(role === "superadmin")) {
        config = setHeaders();
      }
      return await axios.post("/health-vitale", payload, config).then((res) => {
        const response: { data: { healthManager: HealthManager } } = res;
        dispatch(slice.actions.uploadHealthDocuments(response.data));
        return res;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
      return error.error;
    }
  };
}

export function updateUserHealthDocument(
  role?: string,
  id?: string,
  payload?: any
) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let config;
      if (!(role === "superadmin")) {
        config = setHeaders();
      }
      return await axios
        .patch(`/health-vitales/${id}`, payload, config)
        .then((res) => {
          const response: { data: any } = res;
          dispatch(slice.actions.updateHealthSuccess(response.data));
          return res;
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.error;
    }
  };
}

export function deleteHealthDocs(id: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios.delete(`/health-vitales/${id}`).then((res) => {
        const response: { data: any } = res;
        dispatch(slice.actions.deleteHealthDocs(response.data));
        return res;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
