import * as Yup from "yup";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Stack, Alert, Typography, Button, Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import { FormProvider, RHFTextField } from "../../../components/hook-form";
import { useLocation, useNavigate } from "react-router";
import { PATH_AUTH } from "src/routes/paths";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useDispatch } from "src/redux/store";
import { forgetPassword } from "src/redux/slices/auth";
import { useSnackbar } from "notistack";

// ----------------------------------------------------------------------

type FormValuesProps = {
  oldPassword: string;
  afterSubmit?: string;
};

export default function EmailVerificationForm() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();

  const LoginSchema = Yup.object().shape({
    oldPassword: Yup.string().required(t("temporaryPasswordRequired")),
  });

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
    defaultValues: { oldPassword: "" },
  });

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    if (location?.state?.email) {
      navigate(PATH_AUTH.changePassword, {
        state: {
          customData: data,
          locationData: location.state,
        },
      });
    } else {
      enqueueSnackbar("Please Try Again!", { variant: "error" });
    }
  };

  const resendMail = async () => {
    if (location?.state?.email) {
      await dispatch(forgetPassword({ email: location?.state?.email })).then(
        (res: any) => {
          new Promise((resolve) => {
            if (res && res.status === 200) {
              enqueueSnackbar(res?.data?.message);
              resolve(true);
            } else {
              enqueueSnackbar(res?.message, { variant: "error" });
              resolve(true);
            }
          });
        }
      );
    } else {
      enqueueSnackbar("Email not found!", { variant: "error" });
    }
  };

  return (
    <FormProvider
      methods={methods}
      onSubmit={handleSubmit(onSubmit)}
      authentication={true}
    >
      <Stack spacing={3} sx={{ mb: 2 }}>
        {!!errors.afterSubmit && (
          <Alert severity="error">{errors.afterSubmit.message}</Alert>
        )}
        <RHFTextField
          id={"emailVerification.input.verifyEmail"}
          name="oldPassword"
          label={t("enterTemporaryPassword")}
        />
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        id={"emailVerification.button.proceed"}
        variant="contained"
        loading={isSubmitting}
      >
        {t("proceed")}
      </LoadingButton>
      <Box
        sx={{
          mt: 2,
          cursor: "pointer",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <Button
          id={"emailVerification.button.resendEmail"}
          onClick={resendMail}
        >
          {t("resendMail")}
        </Button>
      </Box>
    </FormProvider>
  );
}