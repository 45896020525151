import { ReactNode } from "react";
// form
import { FormProvider as Form, UseFormReturn } from "react-hook-form";

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
  methods: UseFormReturn<any>;
  type?: string;
  onSubmit?: VoidFunction;
  authentication?: boolean;
};

export default function FormProvider({
  children,
  onSubmit,
  methods,
  type,
  authentication
}: Props) {
  return (
    <Form {...methods}>
      <form style={{ ...(!authentication && { paddingLeft: 18, paddingRight: 18 }) }} onSubmit={onSubmit}>
        {children}
      </form>
    </Form>
  );
}
