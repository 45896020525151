// @mui
import { List, Box, Stack, Typography } from '@mui/material';
// hooks
import useLocales from '../../../hooks/useLocales';
//
import { NavSectionProps } from '../type';
import { ListSubheaderStyle } from './style';
import NavList from './NavList';
import useAuth from 'src/hooks/useAuth';
import LanguagePopover from 'src/layouts/dashboard/header/LanguagePopover';
import Iconify from 'src/components/Iconify';
import { useNavigate } from 'react-router';
import { PATH_AUTH } from 'src/routes/paths';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import StatusModal from 'src/components/modal/StatusModal';
import ProfilePopover from 'src/layouts/dashboard/header/ProfilePopover';
// ----------------------------------------------------------------------

export default function NavSectionVertical({ navConfig, isCollapse, ...other }: NavSectionProps) {
  const { translate } = useLocales();
  const { userRole, logout } = useAuth();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const theme = useTheme();
  const [confirmationModal, setConfirmationModal] = useState(false)

  const handleClickConfirmationModal = async () => {
    try {
      await logout();
      navigate(PATH_AUTH.login, { replace: true });
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  }
  
  const handleLogout = () => setConfirmationModal(true)

  return (
    <Box
      {...other}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      <Box>
        {navConfig.map((group) => (
          <List key={group.subheader} disablePadding sx={{ px: 2 }}>
            <ListSubheaderStyle
              sx={{
                ...(isCollapse && {
                  opacity: 0,
                }),
              }}
            >
              {translate(group.subheader)}
            </ListSubheaderStyle>

            {group.items.map((list) =>
              // NRP-T
              // list.title === 'organization' && !['admin', 'superadmin'].includes(userRole)
              //   || list.title === 'members' && ['admin-health', 'admin-vitals', 'admin-media', 'kit-manager'].includes(userRole)
              //   || list.title === 'teams' && ['admin', 'admin-health', 'admin-vitals', 'admin-media', 'manager',"technical-team" , 'kit-manager'].includes(userRole)
              //   || list.title === 'media' && ['admin-health', 'admin-vitals', 'kit-manager'].includes(userRole)
              //   || list.title === 'gps' && ['admin-health', 'admin-media', 'kit-manager'].includes(userRole)
              //   || list.title === 'travel' && ['admin-health', 'admin-vitals', 'admin-media', 'kit-manager'].includes(userRole)
              //   || list.title === 'health' && ['admin-vitals', 'admin-media', 'kit-manager'].includes(userRole)
              //   || list.title === 'inventoryStore' && !['admin', 'superadmin', 'manager',"technical-team" , 'kit-manager'].includes(userRole)
              //   ? null : (
                (
                  <NavList
                    key={list.title + list.path}
                    data={list}
                    depth={1}
                    hasChildren={!!list.children}
                  />
                )
            )}
          </List>
        ))}
      </Box>
      <Stack
        direction="row"
        sx={{ px: 3, pb: 2 }}
        justifyContent="space-between"
        alignItems="center"
        spacing={{ xs: 0.5, sm: 1.5 }}
      >
        <ProfilePopover handleLogout={handleLogout} />
        <LanguagePopover />
      </Stack>
      <StatusModal
        subHeading={t("message4")}
        logoutConfirmationModal={true}
        closeModal={() => setConfirmationModal(false)}
        openModal={confirmationModal}
        handleConfirm={handleClickConfirmationModal}
      />
    </Box>
  );
}
