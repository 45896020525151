// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/dashboard";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  register: path(ROOTS_AUTH, "/register"),
  loginUnprotected: path(ROOTS_AUTH, "/login-unprotected"),
  registerUnprotected: path(ROOTS_AUTH, "/register-unprotected"),
  verify: path(ROOTS_AUTH, "/verify"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
  newPassword: path(ROOTS_AUTH, "/new-password"),
  forgotPassword: path(ROOTS_AUTH, "/forgot-password"),
  emailVerification: path(ROOTS_AUTH, "/email-verification"),
  changePassword: path(ROOTS_AUTH, "/change-password"),
  changeTemporaryPassword: path(ROOTS_AUTH, "/change-temporary-password"),
  oneTimePassword: path(ROOTS_AUTH, "/one-time-password"),
  termAndCondition: path(ROOTS_AUTH, "/terms-and-conditions"),
};

export const PATH_PAGE = {
  comingSoon: "/coming-soon",
  maintenance: "/maintenance",
  pricing: "/pricing",
  payment: "/payment",
  about: "/about-us",
  contact: "/contact-us",
  faqs: "/faqs",
  page403: "/403",
  page404: "/404",
  page500: "/500",
  components: "/components",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, "/app"),
  },
  user: {
    root: path(ROOTS_DASHBOARD, "/user"),
    new: path(ROOTS_DASHBOARD, "/user/new"),
    list: path(ROOTS_DASHBOARD, "/user/list"),
    cards: path(ROOTS_DASHBOARD, "/user/cards"),
    profile: path(ROOTS_DASHBOARD, "/user/profile"),
    account: path(ROOTS_DASHBOARD, "/user/account"),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
};

export const PATH_GROUP_PAGE = {
  root: ROOTS_DASHBOARD,
  organization: {
    list: path(ROOTS_DASHBOARD, "/organization"),
  },

  members: {
    list: path(ROOTS_DASHBOARD, "/members"),
  },

  teams: {
    list: path(ROOTS_DASHBOARD, "/teams"),
  },

  tournament: {
    list: path(ROOTS_DASHBOARD, "/tournament"),
  },

  media: {
    list: path(ROOTS_DASHBOARD, "/media"),
    details: path(ROOTS_DASHBOARD, "/media/media-images"),
    imageDetails: path(ROOTS_DASHBOARD, "/media/media-images-details"),
  },

  vitals: {
    list: path(ROOTS_DASHBOARD, "/gps"),
    details: path(ROOTS_DASHBOARD, "/gps/gps-documents"),
    imageDetails: path(ROOTS_DASHBOARD, "/gps/gps-images-details"),
  },

  travel: {
    list: path(ROOTS_DASHBOARD, "/travel"),
    details: path(ROOTS_DASHBOARD, "/travel/travel-documents"),
  },

  health: {
    list: path(ROOTS_DASHBOARD, "/health"),
    details: path(ROOTS_DASHBOARD, "/health/health-documents"),
  },

  kit: {
    list: path(ROOTS_DASHBOARD, "/kit"),
  },

  inventoryStore: {
    list: path(ROOTS_DASHBOARD, "/inventory-store"),
  },
};
