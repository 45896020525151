import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
// slices
import calendarReducer from "./slices/calendar";
import authReducre from "./slices/auth";
import organizationReducer from "./slices/organization";
import membersReducer from "./slices/memberSlice";
import teamReducer from "./slices/teamSlice";
import tournamentReducer from "./slices/tournamentSlice";
import mediaReducer from "./slices/mediaSlice";
import vitalsReducer from "./slices/vitalsSlice";
import healthReducer from "./slices/healthSlice";
import travelReducer from "./slices/travelSlice";
import kitReducer from "./slices/kitSlice";
import inventoryStoreReducer from "./slices/inventoryStoreSlice";

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const rootReducer = combineReducers({
  auth: authReducre,
  organization: organizationReducer,
  calendar: calendarReducer,
  members: membersReducer,
  teams: teamReducer,
  tournaments: tournamentReducer,
  media: mediaReducer,
  vitals: vitalsReducer,
  healths: healthReducer,
  travels: travelReducer,
  kit: kitReducer,
  inventoryStore: inventoryStoreReducer,
});

export { rootPersistConfig, rootReducer };
