import * as Yup from "yup";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Stack, Alert } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
// routes
import { PATH_AUTH } from "../../../routes/paths";
// components
import { FormProvider, RHFTextField } from "../../../components/hook-form";
import { useTranslation } from "react-i18next";
import { forgetPassword } from "src/redux/slices/auth";
import { useDispatch } from "src/redux/store";
import { useSnackbar } from "notistack";

// ----------------------------------------------------------------------

type FormValuesProps = {
  email: string;
  afterSubmit?: string;
};

export default function ForgotPasswordForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const LoginSchema = Yup.object().shape({
    email: Yup.string().email(t("emailValidate")).required(t("emailRequired")),
  });

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
    defaultValues: { email: "" },
  });

  const {
    reset,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    await dispatch(forgetPassword({ email: data.email })).then((res: any) => {
      new Promise((resolve) => {
        if (res && res.status === 200) {
          enqueueSnackbar(res?.data?.message);
          reset();
          navigate(PATH_AUTH.emailVerification, { state: data });
          resolve(true);
        } else {
          enqueueSnackbar(res?.message, { variant: "error" });
          reset();
          resolve(true);
        }
      });
    });
  };

  return (
    <FormProvider
      methods={methods}
      onSubmit={handleSubmit(onSubmit)}
      authentication={true}
    >
      <Stack spacing={3} sx={{ mb: 2 }}>
        {!!errors.afterSubmit && (
          <Alert severity="error">{errors.afterSubmit.message}</Alert>
        )}
        <RHFTextField
          id={"forgotPassword.input.email"}
          name="email"
          label={t("emailAddress")}
        />
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        id={"forgotPassword.button.submit"}
        variant="contained"
        loading={isSubmitting}
      >
        {t("submit")}
      </LoadingButton>
    </FormProvider>
  );
}
