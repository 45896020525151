import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";
import { KitList, KitState, KitStoreList } from "src/@types/kit";
import { setHeaders } from "src/utils/jwt";

// ----------------------------------------------------------------------

const initialState: KitState = {
  isLoading: false,
  error: null,
  kit: [],
  kitStoreList: [],
  kitManager: null,
  kitStoreManager: null,
};

const slice = createSlice({
  name: "kit",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Kit
    getKitSuccess(state, action) {
      state.isLoading = false;
      state.kit = action.payload;
    },

    // Get kit store list
    getKitStoreSuccess(state, action) {
      // state.isLoading = false;
      state.kitStoreList = action.payload;
    },

    // Upload kit
    uploadKit(state, action) {
      state.isLoading = false;
      state.kitManager = action.payload;
    },

    // Upload kit store
    uploadKitStore(state, action) {
      state.isLoading = false;
      state.kitStoreManager = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {} = slice.actions;

// ----------------------------------------------------------------------

export function getKit(role?: string, payload?: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let config;
      if (!(role === "superadmin")) {
        config = setHeaders();
      }
      await axios
        .get(`/kit-item-requests`, { ...payload, ...config })
        .then((res) => {
          const response: { data: { kit: KitList[] } } = res.data;
          dispatch(slice.actions.getKitSuccess(response.data));          
          return res;
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getKitStore(role?: string, filter?: string) {
  return async () => {
    // dispatch(slice.actions.startLoading());
    try {
      let config;
      if (!(role === "superadmin")) {
        config = setHeaders();
      }
      await axios
        .get(`/kit-items${filter ? filter : ``}`, config)
        .then((res) => {
          const response: { data: { kitStoreList: KitStoreList[] } } = res.data;
          dispatch(slice.actions.getKitStoreSuccess(response.data));
          return res;
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function uploadKit(payload: any, role: string) {
  return async () => {
    try {
      let config;
      if (!(role === "superadmin")) {
        config = setHeaders();
      }
      return await axios
        .post("/kit-item-requests", payload, config)
        .then((res) => {
          const response: { data: { kitManager: KitList } } = res;
          dispatch(slice.actions.uploadKit(response.data));
          return res;
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
      return error.error;
    }
  };
}

// ----------------------------------------------------------------------

export function uploadKitStore(payload: any, role: string) {
  return async () => {
    try {
      let config;
      if (!(role === "superadmin")) {
        config = setHeaders();
      }
      return await axios.post("/kit-items", payload, config).then((res) => {
        const response: { data: { kitStoreManager: KitStoreList } } = res;
        dispatch(slice.actions.uploadKitStore(response.data));
        return res;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
      return error.error;
    }
  };
}

// ----------------------------------------------------------------------

export function deleteKitItem(id: string) {
  return async () => {
    try {
      return await axios.delete(`/kit-item-requests/${id}`).then((res) => {
        return res;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteKitImageItem(id: string) {
  return async () => {
    try {
      return await axios.delete(`/kit-items/${id}`).then((res) => {
        return res;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}