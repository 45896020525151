import { Suspense, lazy, ElementType } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// hooks
import useAuth from "../hooks/useAuth";
// layouts
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
import DashboardLayout from "../layouts/dashboard";
// guards
import GuestGuard from "../guards/GuestGuard";
// config
// components
import LoadingScreen from "../components/LoadingScreen";
// import GroupPageLayout from 'src/layouts/groupPage';
import {
  PATH_AFTER_LOGIN_WITH_ADMIN,
  PATH_AFTER_LOGIN_WITH_MANAGER,
  PATH_AFTER_LOGIN_WITH_OTHERS,
} from "src/config";
import AuthGuard from "src/guards/AuthGuard";
import RoleBasedGuard from "src/guards/RoleBasedGuard";
// NRP-T
import { PERMISSION_TYPE } from "src/@types/permissionConstants";

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes("/dashboard") && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const { userRole } = useAuth();

  return useRoutes([
    // Authentication routes
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        // { path: "forgot-password", element: <ForgotPassword /> },
        // { path: "email-verification", element: <EmailVerification /> },
        // { path: "change-password", element: <ChangePassword /> },
        // {
        //   path: "change-temporary-password",
        //   element: <ChangeTemporaryPassword />,
        // },
        {
          path: "one-time-password",
          element: (
            <GuestGuard>
              <OneTimePassword />
            </GuestGuard>
          ),
        },
        {
          path: "terms-and-conditions",
          element: (
            <GuestGuard>
              <TermAndCondition />
            </GuestGuard>
          ),
        },
      ],
    },
    // Dashboard Routes
    {
      path: "dashboard",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          element: (
            <Navigate
              to={
                userRole && ["manager", "technical-team"].includes(userRole)
                  ? PATH_AFTER_LOGIN_WITH_MANAGER
                  : userRole &&
                    [
                      "admin-health",
                      "admin-vitals",
                      "admin-media",
                      "kit-manager",
                    ].includes(userRole)
                  ? PATH_AFTER_LOGIN_WITH_OTHERS
                  : PATH_AFTER_LOGIN_WITH_ADMIN
              }
              replace
            />
          ),
          index: true,
        },
        // {
        //   path: 'organization',
        //   element:
        //     <RoleBasedGuard hasContent={true} roles={['admin', 'superadmin']}>
        //       <Organization />
        //     </RoleBasedGuard>
        // },
        {
          path: "members",
          element: (
            <RoleBasedGuard
              hasContent={true}
              // NRP-T
              // roles={["admin", "superadmin", "manager", "technical-team"]}
            >
              <Members />
            </RoleBasedGuard>
          ),
        },
        {
          path: "teams",
          element: (
            <RoleBasedGuard
              hasContent={true}
              // NRP-T
              // roles={["superadmin"]}
            >
              <Teams />
            </RoleBasedGuard>
          ),
        },
        {
          path: "tournament",
          element: (
            <RoleBasedGuard
              hasContent={true}
              // NRP-T
              // roles={["admin", "superadmin", "manager", "technical-team", "admin-media", "admin-vitals", "admin-health", "kit-manager"]}
              permissions={[PERMISSION_TYPE.campTournament]}
            >
              <Tournament />
            </RoleBasedGuard>
          ),
        },
        {
          path: "media",
          children: [
            {
              element: (
                <RoleBasedGuard
                  hasContent={true}
                  // NRP-T
                  // roles={["admin", "superadmin", "manager", "technical-team", "admin-media"]}
                  permissions={[PERMISSION_TYPE.media]}
                >
                  <Media />
                </RoleBasedGuard>
              ),
              index: true,
            },
            {
              path: "media-images",
              element: (
                <RoleBasedGuard
                  hasContent={true}
                  // NRP-T
                  // roles={["admin", "superadmin", "manager", "technical-team", "admin-media"]}
                  permissions={[PERMISSION_TYPE.media]}
                >
                  <MediaDetails />
                </RoleBasedGuard>
              ),
            },
            {
              path: "media-images-details",
              element: (
                <RoleBasedGuard
                  hasContent={true}
                  // NRP-T
                  // roles={["admin", "superadmin", "manager", "technical-team", "admin-media"]}
                  permissions={[PERMISSION_TYPE.media]}
                >
                  <MediaFolderView />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "gps",
          children: [
            {
              element: (
                <RoleBasedGuard
                  hasContent={true}
                  // NRP-T
                  // roles={["admin", "superadmin", "manager", "technical-team", "admin-vitals"]}
                  permissions={[PERMISSION_TYPE.gps]}
                >
                  <Vitals />
                </RoleBasedGuard>
              ),
              index: true,
            },
            {
              path: "gps-documents",
              element: (
                <RoleBasedGuard
                  hasContent={true}
                  // NRP-T
                  // roles={["admin", "superadmin", "manager", "technical-team", "admin-vitals"]}
                  permissions={[PERMISSION_TYPE.gps]}
                >
                  <VitalsDetails />
                </RoleBasedGuard>
              ),
            },
            {
              path: "gps-images-details",
              element: (
                <RoleBasedGuard
                  hasContent={true}
                  // NRP-T
                  // roles={["admin", "superadmin", "manager", "technical-team", "admin-vitals"]}
                  permissions={[PERMISSION_TYPE.gps]}
                >
                  <VitalFolderView />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "travel",
          children: [
            {
              element: (
                <RoleBasedGuard
                  hasContent={true}
                  // NRP-T
                  // roles={["admin", "superadmin", "manager", "technical-team"]}
                  permissions={[PERMISSION_TYPE.travel]}
                >
                  <Travel />
                </RoleBasedGuard>
              ),
              index: true,
            },
            {
              path: "travel-documents",
              element: (
                <RoleBasedGuard
                  hasContent={true}
                  // NRP-T
                  // roles={["admin", "superadmin", "manager", "technical-team"]}
                  permissions={[PERMISSION_TYPE.travel]}
                >
                  <TravelsDetails />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "health",
          children: [
            {
              element: (
                <RoleBasedGuard
                  hasContent={true}
                  // NRP-T
                  // roles={["admin", "superadmin", "manager", "technical-team", "admin-health"]}
                  permissions={[PERMISSION_TYPE.health]}
                >
                  <Health />
                </RoleBasedGuard>
              ),
              index: true,
            },
            {
              path: "health-documents",
              element: (
                <RoleBasedGuard
                  hasContent={true}
                  // NRP-T
                  // roles={["admin", "superadmin", "manager", "technical-team", "admin-health"]}
                  permissions={[PERMISSION_TYPE.health]}
                >
                  <HealthsDetails />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "kit",
          element: (
            <RoleBasedGuard
              hasContent={true}
              // NRP-T
              // roles={["admin", "superadmin", "manager", "technical-team", "kit-manager"]}
              permissions={[PERMISSION_TYPE.kit]}
            >
              <Kit />
            </RoleBasedGuard>
          ),
        },
        {
          path: "inventory-store",
          element: (
            <RoleBasedGuard
              hasContent={true}
              // NRP-T
              // roles={["admin", "superadmin", "manager", "technical-team", "kit-manager"]}
              permissions={[PERMISSION_TYPE.inventory]}
            >
              <InventoryStore />
            </RoleBasedGuard>
          ),
        },
      ],
    },
    // Initial routes
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: "/",
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import("../pages/auth/Login")));
const ForgotPassword = Loadable(
  lazy(() => import("../pages/auth/ForgotPassword"))
);
const EmailVerification = Loadable(
  lazy(() => import("../pages/auth/EmailVerification"))
);
const ChangePassword = Loadable(
  lazy(() => import("../pages/auth/ChangePassword"))
);
const ChangeTemporaryPassword = Loadable(
  lazy(() => import("../pages/auth/ChangeTemporaryPassword"))
);
const OneTimePassword = Loadable(
  lazy(() => import("../pages/auth/OneTimePassword"))
);
const TermAndCondition = Loadable(
  lazy(() => import("../pages/auth/TermAndCondition"))
);

// Organization
const Organization = Loadable(
  lazy(() => import("../pages/user-view/organization/Organization"))
);

// Members
const Members = Loadable(
  lazy(() => import("../pages/user-view/members/Members"))
);

// Teams
const Teams = Loadable(lazy(() => import("../pages/user-view/teams/Teams")));

// Tournament
const Tournament = Loadable(
  lazy(() => import("../pages/user-view/tournament/Tournament"))
);

// Media
const Media = Loadable(lazy(() => import("../pages/user-view/media/Media")));
const MediaDetails = Loadable(
  lazy(() => import("../pages/user-view/media/MemberMediaImages"))
);
const MediaFolderView = Loadable(
  lazy(() => import("../pages/user-view/media/MediaFolderView"))
);

// Vitals
const Vitals = Loadable(lazy(() => import("../pages/user-view/vitals/Vitals")));
const VitalsDetails = Loadable(
  lazy(() => import("../pages/user-view/vitals/VitalsDetails"))
);
const VitalFolderView = Loadable(
  lazy(() => import("../pages/user-view/vitals/VitalFolderView"))
);

// Travel
const Travel = Loadable(lazy(() => import("../pages/user-view/travel/Travel")));
const TravelsDetails = Loadable(
  lazy(() => import("../pages/user-view/travel/TravelsDetails"))
);

// Health
const Health = Loadable(lazy(() => import("../pages/user-view/health/Health")));
const HealthsDetails = Loadable(
  lazy(() => import("../pages/user-view/health/HealthDetails"))
);

// Kit
const Kit = Loadable(lazy(() => import("../pages/user-view/kit/kit")));

// Inventory Store
const InventoryStore = Loadable(
  lazy(() => import("../pages/user-view/inventoryStore/InventoryStore"))
);

const Page404 = Loadable(lazy(() => import("../pages/Page404")));
