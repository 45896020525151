import { useDropzone } from "react-dropzone";
// @mui
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
// type
import { UploadProps } from "./type";
//
import Image from "../Image";
import RejectionFiles from "./RejectionFiles";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Iconify from "../Iconify";

// ----------------------------------------------------------------------

const DropZoneStyle = styled("div")(({ theme }) => ({
  outline: "none",
  overflow: "hidden",
  position: "relative",
  padding: theme.spacing(1.8, 1),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create("padding"),
  backgroundColor: theme.palette.background.neutral,
  cursor: "pointer",
}));

// ----------------------------------------------------------------------

export default function UploadSingleFile({
  id,
  error = false,
  file,
  helperText,
  sx,
  ...other
}: UploadProps) {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections,
  } = useDropzone({
    multiple: false,
    maxSize: 20 * 1024 * 1024,
    ...other,
  });

  const { t } = useTranslation();

  return (
    <Box id={id} sx={{ width: "100%", ...sx }}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: "error.main",
            borderColor: "error.light",
            bgcolor: "error.lighter",
          }),
          ...(file && {
            padding: "40% 0",
          }),
        }}
      >
        <input {...getInputProps()} />

        {/* <BlockContent /> */}

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            px: 1,
          }}
        >
          <Typography
            variant="body2"
            sx={{ fontSize: "92%", color: "#95a2af" }}
          >
            {t("selectImage")}
          </Typography>
          <Iconify icon={"mdi:image-add"} color={"GrayText"} />
        </Box>

        {file && (
          <Image
            alt="file preview"
            src={typeof file === "string" ? file : file.preview}
            sx={{
              top: 8,
              left: 8,
              borderRadius: 1,
              position: "absolute",
              width: "calc(100% - 16px)",
              height: "calc(100% - 16px)",
            }}
          />
        )}
      </DropZoneStyle>

      {fileRejections.length > 0 && (
        <RejectionFiles fileRejections={fileRejections} />
      )}

      {helperText && helperText}
    </Box>
  );
}
