import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";
import {
  InventoreStoreState,
  InventoryOrderRequestList,
  InventoryStoreList,
  InventoryStoreManager,
  InventoryOrderRequestManager,
  InventoryOrderRequestCommentManager,
  InventoryOrderRequestComment,
} from "src/@types/inventoryStore";
import { setHeaders } from "src/utils/jwt";

// ----------------------------------------------------------------------

const initialState: InventoreStoreState = {
  isLoading: false,
  error: null,
  inventoryOrderRequest: [],
  inventoryOrderDetailById: null,
  inventoryStoreList: [],
  inventoryStoreDetailById: null,
  inventoryStoreManager: null,
  inventoryOrderRequestManager: null,
  inventoryOrderRequestCommentManager: null,
  inventoryOrderRequestComment: [],
};

const slice = createSlice({
  name: "inventoryStore",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Inventory Order Request List
    getInventoryOrderRequestListSuccess(state, action) {
      state.isLoading = false;
      state.inventoryOrderRequest = action.payload;
    },

    // Get Inventory Order Detail By Id
    getInventoryOrderDetailById(state, action) {
      state.inventoryOrderDetailById = action.payload;
    },

    // Update Inventory Order Request By Id
    updateInventoryOrderRequest(state, action) {
      state.inventoryOrderRequestManager = action.payload;
    },

    // Update Inventory Order Request Item By Id
    updateInventoryOrderRequestItem(state, action) {
      state.inventoryOrderRequestManager = action.payload;
    },

    // Add Inventory Order Request Comment
    addInventoryRequestComment(state, action) {
      state.inventoryOrderRequestCommentManager = action.payload;
    },

    // Get Inventory Order Request Comment By Id
    getInventoryRequestCommentById(state, action) {
      state.inventoryOrderRequestComment = action.payload;
    },

    // GET Inventory Store List
    getInventoryStoreListSuccess(state, action) {
      state.isLoading = false;
      state.inventoryStoreList = action.payload;
    },

    // Get Inventory Store Detail By Id
    getInventoryStoreDetailByIds(state, action) {
      state.inventoryStoreDetailById = action.payload;
    },

    // Add Inventory Store
    addInventoryStore(state, action) {
      state.inventoryStoreManager = action.payload;
    },

    // Update Inventory Store
    updateInventoryStore(state, action) {
      state.isLoading = false;
      state.inventoryStoreManager = action.payload;
    },

  },
});

// Reducer
export default slice.reducer;

// Actions
export const {} = slice.actions;

// ----------------------------------------------------------------------

// Inventory Order Request

export function getInventoryOrderRequestList(role?: string, payload?: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let config;
      if (!(role === "superadmin")) {
        config = setHeaders();
      }
      await axios
        .get(`/inventory-requests`, { ...payload, ...config })
        .then((res) => {
          const response: {
            data: { inventoryOrderRequest: InventoryOrderRequestList[] };
          } = res.data;
          dispatch(
            slice.actions.getInventoryOrderRequestListSuccess(response.data)
          );
          return res;
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getInventoryOrderRequestDetailById(role?: string, id?: string) {
  return async () => {
    try {
      let config;
      if (!(role === "superadmin")) {
        config = setHeaders();
      }
      await axios.get(`/inventory-requests/${id}`, config).then((res) => {
        const response: {
          data: { inventoryOrderDetailById: InventoryOrderRequestList };
        } = res.data;
        dispatch(slice.actions.getInventoryOrderDetailById(response.data));
        return res;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateInventoryOrderRequestById(
  role?: string,
  id?: string,
  payload?: any
) {
  return async () => {
    try {
      let config;
      if (!(role === "superadmin")) {
        config = setHeaders();
      }
      return await axios
        .put(`/inventory-requests-approved/${id}`, payload, config)
        .then((res) => {
          const response: { data: any } = res;
          dispatch(slice.actions.updateInventoryOrderRequest(response.data));
          return res;
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.error;
    }
  };
}

export function updateInventoryOrderRequestItemById(
  role?: string,
  id?: string,
  payload?: any
) {
  return async () => {
    try {
      let config;
      if (!(role === "superadmin")) {
        config = setHeaders();
      }
      return await axios
        .patch(`/inventory-request-items/${id}`, payload, config)
        .then((res) => {
          const response: { data: any } = res;
          dispatch(
            slice.actions.updateInventoryOrderRequestItem(response.data)
          );
          return res;
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.error;
    }
  };
}

// Inventory Comment

export function addInventoryOrderRequestComment(role: string, payload: any) {
  return async () => {
    try {
      let config;
      if (!(role === "superadmin")) {
        config = setHeaders();
      }
      return await axios
        .post("/inventroy-request-comments", payload, config)
        .then((res) => {
          const response: {
            data: {
              inventoryOrderRequestCommentManager: InventoryOrderRequestCommentManager;
            };
          } = res;
          dispatch(slice.actions.addInventoryRequestComment(response.data));
          return res;
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
      return error.error;
    }
  };
}

export function getInventoryOrderRequestCommentById(
  role?: string,
  payload?: any
) {
  return async () => {
    try {
      let config;
      if (!(role === "superadmin")) {
        config = setHeaders();
      }
      await axios
        .get(`/inventroy-request-comments`, { ...payload, ...config })
        .then((res) => {
          const response: {
            data: {
              inventoryOrderRequestComment: InventoryOrderRequestComment[];
            };
          } = res.data;
          dispatch(slice.actions.getInventoryRequestCommentById(response.data));
          return res;
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// Inventory Store

export function getInventoryStoreList(role?: string, payload?: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let config;
      if (!(role === "superadmin")) {
        config = setHeaders();
      }
      await axios.get(`/inventories`, { ...payload, ...config }).then((res) => {
        const response: {
          data: { inventoryStoreList: InventoryStoreList[] };
        } = res.data;
        dispatch(slice.actions.getInventoryStoreListSuccess(response.data));
        return res;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getInventoryStoreDetailById(role?: string, id?: string) {
  return async () => {
    try {
      let config;
      if (!(role === "superadmin")) {
        config = setHeaders();
      }
      await axios.get(`/inventories/${id}`, config).then((res) => {
        const response: {
          data: { inventoryStoreDetailById: InventoryStoreList };
        } = res.data;
        dispatch(slice.actions.getInventoryStoreDetailByIds(response.data));
        return res;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addInventoryStoreItem(role: string, payload: any) {
  return async () => {
    try {
      let config;
      if (!(role === "superadmin")) {
        config = setHeaders();
      }
      return await axios.post("/inventories", payload, config).then((res) => {
        const response: {
          data: { inventoryStoreManager: InventoryStoreManager };
        } = res;
        dispatch(slice.actions.addInventoryStore(response.data));
        return res;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
      return error.error;
    }
  };
}

export function updateInventoryStoreItem(
  role?: string,
  id?: string,
  payload?: any
) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let config;
      if (!(role === "superadmin")) {
        config = setHeaders();
      }
      return await axios
        .patch(`/inventories/${id}`, payload, config)
        .then((res) => {
          const response: { data: any } = res;
          dispatch(slice.actions.updateInventoryStore(response.data));
          return res;
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.error;
    }
  };
}

export function deleteInventoryStoreItem(id: string) {
  return async () => {
    try {
      return await axios.delete(`/inventories/${id}`).then((res) => {
        return res;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}