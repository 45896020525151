// NRP-T
export const PERMISSION_TYPE = Object.freeze({
  roster: "roster_module",
  campTournament: "camp_tournament_module",
  bonus: "bonus_module",
  discipline: "discipline_module",
  media: "media_module",
  gps: "gps_module",
  health: "health_module",
  travel: "travel_module",
  kit: "kit_module",
  inventory: "inventory_module",
});

export function updatePermissionTranslation(t: any) {
  const PERMISSIONS = [
    { label: t("permissionRoster"), value: PERMISSION_TYPE.roster },
    {
      label: t("permissionCampTournament"),
      value: PERMISSION_TYPE.campTournament,
    },
    { label: t("permissionBonus"), value: PERMISSION_TYPE.bonus },
    { label: t("permissionDiscipline"), value: PERMISSION_TYPE.discipline },
    { label: t("permissionMedia"), value: PERMISSION_TYPE.media },
    { label: t("permissionGPS"), value: PERMISSION_TYPE.gps },
    { label: t("permissionHealth"), value: PERMISSION_TYPE.health },
    { label: t("permissionTravel"), value: PERMISSION_TYPE.travel },
    { label: t("permissionKit"), value: PERMISSION_TYPE.kit },
    { label: t("permissionInventory"), value: PERMISSION_TYPE.inventory },
  ];
  return { PERMISSIONS };
}
