import * as Yup from "yup";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Stack, Alert, Box, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import { FormProvider, RHFTextField } from "../../../components/hook-form";
import { useLocation, useNavigate } from "react-router";
import { PATH_AUTH } from "src/routes/paths";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useDispatch } from "src/redux/store";
import { useSnackbar } from "notistack";
import useAuth from "src/hooks/useAuth";
import { useState } from "react";
import { sendOtp } from "src/redux/slices/auth";

// ----------------------------------------------------------------------

type FormValuesProps = {
  otp: string;
  afterSubmit?: string;
};

export default function OneTimePasswordForm() {
  const { login } = useAuth();
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const [isResendEmailLoading, setIsResendEmailLoading] = useState(false);

  const LoginSchema = Yup.object().shape({
    otp: Yup.string()
      .required(t("oneTimePasswordRequired"))
      .max(6, t("enterValidOtp"))
      .min(6, t("enterValidOtp")),
  });

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
    defaultValues: { otp: "" },
  });

  const {
    reset,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    let payload;
    if (location?.state?.email) {
      payload = {
        email: location?.state?.email.toLowerCase(),
        otp: data.otp,
      };
    } else {
      payload = {
        phone: location?.state?.phone,
        countryCode: location?.state?.countryCode,
        otp: data.otp,
      };
    }
    await login(payload)
      .then((res) => {
        new Promise((resolve) => {
          reset();
          if (res) {
            if (res.statusCode === 401 || res.statusCode === 422) {
              enqueueSnackbar(res.message, { variant: "error" });
            } else if (res?.status === 200 && res.data?.data?.onboard) {
              resolve(true);
            } else {
              navigate(
                `${PATH_AUTH.termAndCondition}?token=${res?.data?.data?.token}`,
                { state: { data: res?.data } }
              );
            }
          } else {
            enqueueSnackbar(
              "Internal Server Error : Please Try After Some Time !",
              { variant: "error" }
            );
          }
          resolve(true);
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const resendMail = async () => {
    let payload;
    if (location?.state?.email) {
      payload = {
        email: location?.state?.email.toLowerCase(),
      };
    } else {
      payload = {
        phone: location?.state?.phone,
        countryCode: location?.state?.countryCode,
      };
    }
    setIsResendEmailLoading(true);
    await dispatch(sendOtp(payload))
      .then((res: any) => {
        new Promise((resolve) => {
          if (res && res.status === 200 && res.data?.statusCode === 200) {
            enqueueSnackbar(res?.data?.message);
            resolve(true);
            setIsResendEmailLoading(false);
          } else {
            enqueueSnackbar(res?.message, { variant: "error" });
            resolve(true);
            setIsResendEmailLoading(false);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const email = location?.state?.email;
  const countryCode = location?.state?.countryCode;
  const phone = location?.state?.phone;

  const loginCodePrompt = `${t("enterTheLoginCodeSent")} ${
    location?.state ? (email ? email : "+" + countryCode + "-" + phone) : "?"
  }`;

  return (
    <FormProvider
      methods={methods}
      onSubmit={handleSubmit(onSubmit)}
      authentication={true}
    >
      <Stack spacing={3} sx={{ mb: 2 }}>
        {!!errors.afterSubmit && (
          <Alert severity="error">{errors.afterSubmit.message}</Alert>
        )}
        <Typography variant="subtitle2" align="center">
          {loginCodePrompt}
        </Typography>
        <RHFTextField
          id={"oneTimePassword.input.verifyEmail"}
          name="otp"
          placeholder={t("enterOneTimePassword")}
          type="number"
        />
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        id={"oneTimePassword.button.login"}
        variant="contained"
        loading={isSubmitting}
      >
        {t("proceed")}
      </LoadingButton>
      <Box
        sx={{
          mt: 2,
          cursor: "pointer",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <LoadingButton
          fullWidth
          id={"oneTimePassword.button.resendEmail"}
          onClick={resendMail}
          loading={isResendEmailLoading}
        >
          {t("resendOneTimePassword")}
        </LoadingButton>
      </Box>
    </FormProvider>
  );
}
