import { useState } from "react";
// @mui
import { MenuItem, Stack, Typography } from "@mui/material";

// components
import Image from "../../../components/Image";
import MenuPopover from "../../../components/MenuPopover";
import Iconify from "src/components/Iconify";
import { useTranslation } from "react-i18next";
import useAuth from "src/hooks/useAuth";
import MyAvatar from "src/components/MyAvatar";
// ----------------------------------------------------------------------

interface Props {
  handleLogout: () => void;
}

export default function ProfilePopover({ handleLogout }: Props) {
  const { userRole, user } = useAuth();
  
  const [open, setOpen] = useState<HTMLElement | null>(null);
  const { t } = useTranslation();

  const handleOpen = (event: React.MouseEvent<HTMLElement>) =>
    setOpen(event.currentTarget);

  const handleClose = () => setOpen(null);

  const isArabic = localStorage.getItem("i18nextLng") === "ar";

  const displayProfileNamewithRole = isArabic
    ? `${user?.localFirstName}${" "}${user?.localLastName}${" "}(${userRole})`
    : `${user?.firstName}${" "}${user?.lastName}${" "}(${userRole})`;

  return (
    <>
      <Stack
        onClick={handleOpen}
        sx={{ flexDirection: "row" }}
        id="sidebar.popover.logout"
      >
        <MyAvatar />
        <Image
          disabledEffect
          src={require("../../../assets/images/dropdown.png")}
          alt={"dropdown"}
          sx={{
            width: "16px",
            height: "16px",
            margin: "10px",
            ml: 0.5,
            ...(open && { bgcolor: "action.selected" }),
          }}
        />
      </Stack>
      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 300,
          "& .MuiMenuItem-root": {
            px: 1,
            typography: "body2",
            borderRadius: 0.75,
          },
        }}
      >
        <Stack spacing={0.75}>
          <Stack sx={{ flexDirection: "row", p: 1 }}>
            <MyAvatar
              sx={{ mr: 1, width: 34, height: 34, alignSelf: "center" }}
            />
            <Stack sx={{ p: 0.5, fontSize: 14, flexDirection: "row" }}>
              {displayProfileNamewithRole.length > 50
                ? t(displayProfileNamewithRole?.toUpperCase().slice(0, 45)) +
                  "..."
                : t(displayProfileNamewithRole?.toUpperCase())}
            </Stack>
          </Stack>
          <Stack sx={{ flexDirection: "row", p: 1 }}>
            <Iconify
              icon="ic:baseline-phone"
              width={26}
              height={26}
              marginRight={0.5}
            />
            <Stack sx={{ p: 0.5, fontSize: 14, flexDirection: "row" }}>
              {t(`+${user?.countryCode}${" "}${user?.phone}`)}
            </Stack>
          </Stack>
          <MenuItem id="sidebar.button.logout" onClick={handleLogout}>
            <Iconify icon="solar:logout-bold-duotone" width={30} height={30} />
            <Typography variant="body2" sx={{ pl: 1 }}>
              {t("logout")}
            </Typography>
          </MenuItem>
        </Stack>
      </MenuPopover>
    </>
  );
}