import * as Yup from "yup";
import { useState } from "react";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Stack, Alert, IconButton, InputAdornment } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// routes
// hooks
// components
import Iconify from "../../../components/Iconify";
import { FormProvider, RHFTextField } from "../../../components/hook-form";
import { PATH_AUTH, PATH_GROUP_PAGE } from "src/routes/paths";
import { useLocation, useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { useDispatch } from "src/redux/store";
import { changePassword } from "src/redux/slices/auth";
import { useSnackbar } from "notistack";
import axios from "../../../utils/axios";

// ----------------------------------------------------------------------

type FormValuesProps = {
  newPassword: string;
  password: string;
  afterSubmit?: string;
};

export default function ChangeTemporaryPasswordForm() {
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const LoginSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required(t("newPasswordRequired"))
      .min(8, t("passwordValidate"))
      .max(20, t("passwordValidate3"))
      .matches(
        /^(?=.*[!@#$%^&*(),.?":{}|<>])(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
        t("passwordValidate2")
      ),
    password: Yup.string()
      .required(t("confirmPasswordRequired"))
      .oneOf([Yup.ref("newPassword")], t("passwordValidate4")),
  });

  const defaultValues = {
    newPassword: "",
    password: "",
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const params = new URLSearchParams(location.search);

  const onSubmit = async (data: FormValuesProps) => {
    let payload = {
      oldPassword: location?.state,
      password: data?.password,
    };
    const token = params.get("token");
    try {
      if (location?.state === data?.password) {
        enqueueSnackbar("New Password should not be same as old password", {
          variant: "error",
        });
      } else {
        const response = await dispatch(changePassword(payload, token));
        if (response?.status === 200) {
          enqueueSnackbar("Password changed successfully", {
            variant: "success",
          });
          navigate(PATH_AUTH.login);
        } else {
          enqueueSnackbar("Invalid Password", { variant: "error" });
          reset();
        }
      }
    } catch (error) {
      if (error?.status === 422) {
        enqueueSnackbar("Invalid Password", { variant: "error" });
        reset();
      } else {
        enqueueSnackbar("Invalid Old Password", { variant: "error" });
        reset();
      }
    }
  };

  return (
    <FormProvider
      methods={methods}
      onSubmit={handleSubmit(onSubmit)}
      authentication={true}
    >
      <Stack spacing={3} sx={{ mb: 2 }}>
        {!!errors.afterSubmit && (
          <Alert severity="error">{errors.afterSubmit.message}</Alert>
        )}
        <RHFTextField
          id={"changeTempPassword.input.newPassword"}
          name="newPassword"
          label={t("enterNewPassword")}
          type={showNewPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowNewPassword(!showNewPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showNewPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <RHFTextField
          id={"changeTempPassword.input.confirmPassword"}
          name="password"
          label={t("confirmPassword")}
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        id={"changeTempPassword.button.submit"}
        variant="contained"
        loading={isSubmitting}
      >
        {t("submit")}
      </LoadingButton>
    </FormProvider>
  );
}