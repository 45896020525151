import * as Yup from "yup";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Stack, Alert, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// routes
import { PATH_AUTH } from "../../../routes/paths";
// hooks
import useIsMountedRef from "../../../hooks/useIsMountedRef";
// components
import { FormProvider, RHFTextField } from "../../../components/hook-form";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { sendOtp } from "src/redux/slices/auth";
import { useDispatch } from "src/redux/store";

// ----------------------------------------------------------------------

type FormValuesProps = {
  email: string;
  phone: string;
  afterSubmit?: string;
};

export default function LoginForm() {
  const { enqueueSnackbar } = useSnackbar();
  const [toggleInput, setToggleInput] = useState(true);
  const [state, setState] = useState({
    phoneNumber: "",
    phone: "",
    countryCode: "",
  });
  const { phoneNumber, phone, countryCode } = state;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const LoginSchema = Yup.object().shape({
    ...(!toggleInput && {
      email: Yup.string()
        .email(t("emailValidate"))
        .required(t("emailRequired")),
    }),
    ...(toggleInput && { phone: Yup.string().required(t("phoneRequired")) }),
  });

  const defaultValues = {
    email: "",
    countryCode: "",
    phone: "",
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const handleChangeInput = () => {
    setToggleInput(!toggleInput);
    setState((prev) => ({ ...prev, phone: "" }));
    reset(defaultValues);
  };

  const handleChange = (newPhone: any, phoneObj: any) => {
    let phoneVal = newPhone.replace(phoneObj.dialCode, "");
    setState((prev: any) => ({
      ...prev,
      phone: phoneVal,
      phoneNumber: newPhone,
      countryCode: phoneObj.dialCode,
    }));
    setValue("phone", phoneVal);
  };

  const onSubmit = async (data: FormValuesProps) => {
    // if (toggleInput && phone?.length < 6) {
    //   enqueueSnackbar("Phone number must be have atleast 6 characters", {
    //     variant: "error",
    //   });
    // } else {
    let payload: any;
    if (!toggleInput) {
      payload = {
        email: data.email.toLowerCase(),
      };
    } else {
      payload = {
        phone: phone,
        countryCode: countryCode,
      };
    }
    await dispatch(sendOtp(payload))
      .then((res: any) => {
        new Promise((resolve) => {
          reset();
          setState((prev) => ({
            ...prev,
            phone: "",
            countryCode: "",
          }));
          if (res) {
            if (res.status === 200) {
              if (res.data?.role === "player") {
                enqueueSnackbar("Invalid Credentials", { variant: "error" });
              } else if (res.data?.statusCode === 200) {
                enqueueSnackbar(res.data.message);
                navigate(PATH_AUTH.oneTimePassword, { state: payload });
              } else {
                enqueueSnackbar(res.data.message, { variant: "error" });
              }
            } else if (
              res.data?.statusCode === 401 ||
              res.data?.statusCode === 422 ||
              res.data?.statusCode === 404
            ) {
              enqueueSnackbar(res.data.message, { variant: "error" });
            } else if (res?.statusCode === 400) {
              enqueueSnackbar(res?.message, { variant: "error" });
            } else {
              enqueueSnackbar("Please Try After Some Time !", {
                variant: "error",
              });
            }
          } else {
            enqueueSnackbar(
              "Internal Server Error : Please Try After Some Time !",
              { variant: "error" }
            );
          }
          resolve(true);
        });
      })
      .catch((err: any) => {
        console.log("err", err);
      });
    // }
  };

  return (
    <FormProvider
      methods={methods}
      onSubmit={handleSubmit(onSubmit)}
      authentication={true}
    >
      <Stack spacing={3} sx={{ my: 3 }}>
        {!!errors.afterSubmit && (
          <Alert severity="error">{errors.afterSubmit.message}</Alert>
        )}

        {!toggleInput ? (
          <RHFTextField
            id={"login.input.email"}
            name="email"
            placeholder={t("emailAddress")}
            autoComplete="off"
          />
        ) : (
          <RHFTextField
            id={"login.input.phone"}
            name="phoneNumber"
            inputValue={phoneNumber}
            handleChange={handleChange}
            loginInput={true}
          />
        )}
      </Stack>
      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        id={"login.button.login"}
        variant="contained"
        loading={isSubmitting}
      >
        {t("sendOneTimePassword")}
      </LoadingButton>
      <Stack alignItems="center" justifyContent="center" sx={{ my: 2 }}>
        <Typography
          id={"login.button.toggle"}
          variant="subtitle1"
          sx={{ color: "#667380" }}
          onClick={handleChangeInput}
        >
          {t("or")}
        </Typography>
        <LoadingButton
          fullWidth
          size="large"
          id={"login.button.useLogin"}
          variant="text"
          onClick={handleChangeInput}
        >
          {toggleInput ? t("useEmail") : t("usePhoneNumber")}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
