import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";
import { Vital, VitalTeamList, VitalState } from "src/@types/vital";
import { AxiosRequestConfig } from "axios";
import { setHeaders } from "src/utils/jwt";

// ----------------------------------------------------------------------

const initialState: VitalState = {
  isLoading: false,
  error: null,
  vitals: [],
  vitalsTeamList: [],
  vitalManager: null,
  vitalDetail: null,
  isDeletionAction: false,
};

const slice = createSlice({
  name: "vitals",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MEMBERS
    getVitalsSuccess(state, action) {
      state.isLoading = false;
      state.vitals = action.payload;
    },

    // GET HEALTH DETAIL
    getVitalDetail(state, action) {
      state.isLoading = false;
      state.vitalDetail = action.payload;
    },

    // GET VITALS TEAM
    getVitalTeamSuccess(state, action) {
      state.isLoading = false;
      state.vitalsTeamList = action.payload;
    },

    // UPLOAD VITALS DOCS
    uploadVitalDocuments(state, action) {
      state.vitalManager = action.payload;
    },

    // Set Deletion Action
    setDeletionAction(state, action) {
      state.isDeletionAction = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setDeletionAction } = slice.actions;

// ----------------------------------------------------------------------

export function getVitals(role?: string, filter?: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let config;
      if (!(role === "superadmin")) {
        config = setHeaders();
      }
      await axios
        .get(`/health-vitales?type=vitals${filter ? filter : ""}`, config)
        .then((res) => {
          const response: { data: { vitals: Vital[] } } = res.data;
          const responseTeam: { teamGps: { vitalsTeamList: VitalTeamList[] } } =
            res.data;
          dispatch(slice.actions.getVitalsSuccess(response.data));
          dispatch(slice.actions.getVitalTeamSuccess(responseTeam.teamGps));
          return res;
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getVitalDetails(role?: string, payload?: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let config;
      if (!(role === "superadmin")) {
        config = setHeaders();
      }
      return await axios
        .get(`/health-vitales-user`, { ...payload, ...config })
        .then((res) => {
          const response: { data: { vitalDetial: Vital } } = res.data;
          dispatch(slice.actions.getVitalDetail(response.data));
          return res;
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function uploadVitalDocs(payload: any, role?: string) {
  return async () => {
    try {
      let config;
      if (!(role === "superadmin")) {
        config = setHeaders();
      }
      return await axios.post("/health-vitale", payload, config).then((res) => {
        const response: { data: { vitalManager: Vital } } = res;
        dispatch(slice.actions.uploadVitalDocuments(response.data));
        return res;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
      return error.error;
    }
  };
}

export function deleteVitalImages(payload: any) {
  return async () => {
    try {
      return await axios
        .delete(`/gps-delete`, { data: payload })
        .then((res) => {
          return res;
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
