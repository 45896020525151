import ThemeRtlLayout from './ThemeRtlLayout';
import ThemeLocalization from './ThemeLocalization';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function ThemeSettings({ children }: Props) {
  return (
        <ThemeLocalization>
          <ThemeRtlLayout>
            {children}
          </ThemeRtlLayout>
        </ThemeLocalization>
  );
}
