// @ts-nocheck
// @mui
import { Typography, Box, Checkbox } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import pdf from "../../../assets/data.pdf";
import { useState } from "react";
import useAuth from "src/hooks/useAuth";

export default function TermAndConditionForm() {
  const { onboard } = useAuth();
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  let details = navigator.userAgent;

  let regexp = /android|iphone|kindle|ipad/i;

  let isMobileDevice = regexp.test(details);

  const handleCheckboxChange = (event: any) => {
    setIsChecked(event.target.checked);
  };

  const handleLogin = async () => {
    const token = params.get("token");
    const data = location?.state;
    setIsLoading(true);
    await onboard(token, data).then((res) => {
      if (res && res?.data && res?.data?.statusCode === 200) {
        setIsLoading(false);
      } else {
        enqueueSnackbar("Invalid credentials", { variant: "error" });
        setIsLoading(false);
      }
    });
  };

  return (
    <Box>
      <Box
        style={{
          height: "500px",
          width: "500px",
          alignSelf: "center",
          position: "relative",
        }}
      >
        <iframe
          src={pdf}
          style={{
            height: "100%",
            width: "100%",
            position: "absolute",
            left: 0,
            top: 0,
            borderWidth: "0",
          }}
        />
        <Box
          style={{
            backgroundColor: "#fff",
            position: "absolute",
            top: 0,
            left: 0,
            height: "11%",
            width: "103%",
            display: "block",
            margin: "-2px",
          }}
        >
          <Typography variant="h4" align="center">
            {t("termAndConditions")}
          </Typography>
        </Box>
        {!isMobileDevice && (
          <div
            style={{
              backgroundColor: "#484b4e",
              position: "absolute",
              bottom: 0,
              left: 0,
              height: "0.7%",
              width: "100%",
              display: "block",
            }}
          ></div>
        )}
      </Box>
      <Box display={"flex"} alignItems={"center"}>
        <Checkbox
          checked={isChecked}
          onChange={handleCheckboxChange}
          required
        />
        <Typography variant="subtitle2" sx={{ py: 2, pl: 1 }}>
          {t("iAcceptTheseTermsAndConditions")}
        </Typography>
      </Box>
      <LoadingButton
        fullWidth
        size="large"
        id={"termAndCondition.button.proceed"}
        variant="contained"
        disabled={!isChecked}
        onClick={handleLogin}
        loading={isLoading}
      >
        {t("proceed")}
      </LoadingButton>
    </Box>
  );
}
