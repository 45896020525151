import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";
import { Organizations, OrganizationState } from "src/@types/organization";
// ----------------------------------------------------------------------
const initialState: OrganizationState = {
  isLoading: false,
  error: null,
  organization: null,
};
const slice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // GET organization
    getOrganizationSuccess(state, action) {
      state.isLoading = false;
      state.organization = action.payload;
    },
    // CREATE orgination
    createOrganizationSuccess(state, action) {
      state.isLoading = false;
      state.organization = action.payload;
    },
  },
});
// Reducer
export default slice.reducer;
// Actions
export const { } = slice.actions;
// ----------------------------------------------------------------------

export function getOrganization() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.get(`/organizations`).then((res) => {
        const response: { data: { organization: Organizations } } = res.data;
        dispatch(slice.actions.getOrganizationSuccess(response.data));
        return res;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createOrganization(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      return await axios.post("/organization/add", payload).then((res) => {
        const response: {
          data: { organization: Organizations };
        } = res;
        dispatch(slice.actions.createOrganizationSuccess(response.data));
        return res;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
      return error.error;
    }
  };
}
