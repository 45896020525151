import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";
import { MediaList, MediaState, MediaTeamList } from "src/@types/media";
import { AxiosRequestConfig } from "axios";
import { setHeaders } from "src/utils/jwt";
import _ from "lodash";

// ----------------------------------------------------------------------

const initialState: MediaState = {
  isLoading: false,
  error: null,
  media: [],
  mediaTeam: [],
  mediaManager: null,
  mediaDetail: null,
  mediaFolderDetail: null,
  isDeletionAction: false,
};

const slice = createSlice({
  name: "media",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MEMBERS
    getMediaSuccess(state, action) {
      state.isLoading = false;
      state.media = action.payload;
    },

    // GET TEAM MEDIA
    getMediaTeamSuccess(state, action) {
      state.isLoading = false;
      state.mediaTeam = action.payload;
    },

    // UPLOAD MEDIA DOCS
    uploadMediaDocuments(state, action) {
      state.mediaManager = action.payload;
    },

    // GET MEDIA DETAIL
    getMediaDetail(state, action) {
      state.isLoading = false;
      state.mediaDetail = action.payload;
    },

    // GET MEDIA FOLDER DETAIL
    getMediaFolderDetail(state, action) {
      state.isLoading = false;
      state.mediaFolderDetail = action.payload;
    },

    // CREATE MEDIA FOLDER
    createMediaFolder(state, action) {
      state.isLoading = false;
    },

    // UPDATE MEDIA FOLDER
    updateMediaFolder(state, action) {
      const { isForFolder, id, payload } = action.payload || {};
      const { name } = payload || {};

      const targetStateKey = isForFolder ? "mediaFolderDetail" : "mediaDetail";
      const targetState = state[targetStateKey];
      if (targetState?.data?.children?.length > 0) {
        targetState.data.children = targetState.data.children.map((e: any) =>
          e.id == id ? { ...e, name: name } : e
        );
      }

      state[targetStateKey] = targetState;
      state.isLoading = false;
    },

    // DELETE MEDIA FOLDER
    deleteMediaFolder(state, action) {
      const { isForFolder, id } = action.payload || {};
      const targetStateKey = isForFolder ? "mediaFolderDetail" : "mediaDetail";
      const targetState = state[targetStateKey];
      if (targetState?.data?.children?.length > 0) {
        targetState.data.children = targetState.data.children.filter(
          (e: any) => e.id !== id
        );
      }

      state[targetStateKey] = targetState;
      state.isLoading = false;
    },

    // DELETE MEDIA IMAGE
    deleteMediaImage(state, action) {
      const { isForFolder, payload } = action.payload || {};
      const { data } = payload || {};
      const targetStateKey = isForFolder ? "mediaFolderDetail" : "mediaDetail";
      const targetState = state[targetStateKey];
      if (targetState?.data?.media?.length > 0) {
        targetState.data.media = targetState.data.media.flatMap((e: any) => {
          const updatedAttachments =
            e.attachments?.filter(
              (i: any) =>
                !data.some((j: any) => e.id === j.mediaId && i.id === j.attchId)
            ) || [];
          return updatedAttachments.length > 0
            ? [{ ...e, attachments: updatedAttachments }]
            : [];
        });
      }

      state[targetStateKey] = targetState;
      state.isLoading = false;
    },

    setDeletionAction(state, action) {
      state.isDeletionAction = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setDeletionAction } = slice.actions;

// ----------------------------------------------------------------------

export function getMedia(role?: string, payload?: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let config;
      if (!(role === "superadmin")) {
        config = setHeaders();
      }
      await axios.get(`/media-admin`, { ...payload, ...config }).then((res) => {
        const response: { data: { media: MediaList[] } } = res.data;
        const responseTeam: { team: { mediaTeam: MediaTeamList[] } } = res.data;
        dispatch(slice.actions.getMediaSuccess(response.data));
        dispatch(slice.actions.getMediaTeamSuccess(responseTeam.team));
        return res;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getMediaDetails(
  payload?: any,
  role?: string,
  showLoader?: boolean
) {
  return async () => {
    showLoader && dispatch(slice.actions.startLoading());
    try {
      let config;
      if (!(role === "superadmin")) {
        config = setHeaders();
      }
      return await axios
        .get(`/media-user`, { ...payload, ...config })
        .then((res) => {
          const response: { data: { mediaDetail: MediaList } } = res;
          dispatch(slice.actions.getMediaDetail(response.data));
          return res;
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getMediaFolderDetails(
  id: string,
  role?: string,
  showLoader?: boolean
) {
  return async () => {
    showLoader && dispatch(slice.actions.startLoading());
    try {
      let config;
      if (!(role === "superadmin")) {
        config = setHeaders();
      }
      return await axios.get(`/folders/${id}`, { ...config }).then((res) => {
        const response: { data: { mediaFolderDetail: MediaList } } = res;
        dispatch(slice.actions.getMediaFolderDetail(response.data));
        return res;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createMediaFolders(payload: any, role: string) {
  return async () => {
    // dispatch(slice.actions.startLoading());
    try {
      let config;
      if (!(role === "superadmin")) {
        config = setHeaders();
      }
      return await axios.post("/folders", payload, config).then((res) => {
        dispatch(slice.actions.createMediaFolder(res));
        return res;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
      return error.error;
    }
  };
}

// ----------------------------------------------------------------------

export function updateMediaFolders(
  id: string,
  payload: any,
  isForFolder: boolean
) {
  return async () => {
    // dispatch(slice.actions.startLoading());
    dispatch(slice.actions.updateMediaFolder({ id, payload, isForFolder }));
    try {
      return await axios.patch(`/folders/${id}`, payload).then((res) => {
        // dispatch(slice.actions.updateMediaFolder(res));
        return res;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.error;
    }
  };
}

// ----------------------------------------------------------------------

export function deleteMediaFolders(id: string, isForFolder: boolean) {
  return async () => {
    // dispatch(slice.actions.startLoading());
    dispatch(slice.actions.deleteMediaFolder({ id, isForFolder }));
    try {
      return await axios.delete(`/folders/${id}`).then((res) => {
        // dispatch(slice.actions.deleteMediaFolder(res));
        return res;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function uploadMediaDocs(payload: any, role: string) {
  return async () => {
    try {
      let config;
      if (!(role === "superadmin")) {
        config = setHeaders();
      }
      return await axios.post("/media", payload, config).then((res) => {
        const response: { data: { mediaManager: MediaList } } = res;
        dispatch(slice.actions.uploadMediaDocuments(response.data));
        return res;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
      return error.error;
    }
  };
}

export function deleteMediaImages(payload: any, isForFolder: boolean) {
  return async () => {
    dispatch(slice.actions.deleteMediaImage({ payload, isForFolder }));
    try {
      return await axios
        .delete(`/media-delete`, { data: payload })
        .then((res) => {
          return res;
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
